import payload_plugin_qLmFnukI99 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_KAumv2pUni from "/opt/build/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_2ruDq4uEtU from "/opt/build/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_jtc2qNDx4l from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_zbacBAXhj0 from "/opt/build/repo/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_kyYn5AnnAK from "/opt/build/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_WLsn00x1qh from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_PSbK9A48ZQ from "/opt/build/repo/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import sentry_client_VdOeuw25Mb from "/opt/build/repo/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import auth_redirect_bWtzICY6fh from "/opt/build/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import siteConfig_JRId4KOeUL from "/opt/build/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_JSh5nGhzCz from "/opt/build/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eoILE7jqvj from "/opt/build/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import toast_client_9z8IIRO0Dx from "/opt/build/repo/plugins/toast.client.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/opt/build/repo/.nuxt/vuetify-nuxt-plugin.client.mjs";
import defaults_M8ptihKv0z from "/opt/build/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_2ruDq4uEtU,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_no_client_hints_kyYn5AnnAK,
  plugin_WLsn00x1qh,
  plugin_PSbK9A48ZQ,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  auth_redirect_bWtzICY6fh,
  siteConfig_JRId4KOeUL,
  inferSeoMetaPlugin_JSh5nGhzCz,
  titles_eoILE7jqvj,
  toast_client_9z8IIRO0Dx,
  vuetify_nuxt_plugin_client_JOtFoHdxcp,
  defaults_M8ptihKv0z
]