<script setup>
import { useGlobalStore } from "@/stores/global"
import { computed } from "vue"

const store = useGlobalStore()

const items = computed(() => {
  const returnArray = []

  // Attacker

  if (
    store.profileUiAttacker === "adhoc" &&
    !store.currentAttackerAdHoc.confirmed
  ) {
    returnArray.push("Ad hoc attacker not confirmed.")
  }

  if (store.profileUiAttacker === "profile") {
    if (
      !store.currentAttacker?.id ||
      !store.currentAttackerCompatibleWithAttackType
    ) {
      returnArray.push("Attacker profile not selected.")
    } else if (
      store.currentAttackerCompatibleWithAttackType &&
      store.currentAttackerWeaponsSelectedFiltered.length === 0
    ) {
      returnArray.push("No weapons selected.")
    }
  }

  // Defender

  if (
    store.profileUiDefender === "adhoc" &&
    !store.currentDefenderAdHoc.confirmed
  ) {
    returnArray.push("Ad hoc defender not confirmed.")
  }

  if (store.profileUiDefender === "profile") {
    if (!store.currentDefender?.id) {
      returnArray.push("Defender profile not selected.")
    }
  }

  if (store.simError) {
    returnArray.push(store.simError)
  }

  return returnArray
})
</script>

<template>
  <div class="outer-wrapper">
    <div class="inner-wrapper">
      <h2>Incomplete data for simulation</h2>
      <ul>
        <li v-for="item in items" :key="item">{{ item }}</li>
      </ul>
      <div v-if="store.simError" class="error-actions">
        <h3>Suggestions</h3>
        <ol>
          <li>Try running the sim again.</li>
          <li>Try refreshing the page and then running the sim again.</li>
          <!-- prettier-ignore -->
          <li>Try reducing the number of sims to perform and then running the sim again.</li>
          <!-- prettier-ignore -->
          <li>If the error persists <a href="/send-debug-data">send debug data to the developer</a>.</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.outer-wrapper {
  border-radius: 4px;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  background-image: repeating-linear-gradient(
    45deg,
    #222,
    #222 3px,
    #121212 3px,
    #121212 12px
  );
}

.inner-wrapper {
  display: inline-block;
  background-color: #121212;
  padding: 1.5rem;
}

h2,
h3 {
  margin-bottom: 1rem;
}

ol,
ul {
  list-style-position: inside;
}

li {
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.error-actions {
  margin-top: 1rem;
}
</style>
