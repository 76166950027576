import { removeAllWhitespace } from "~/utils/utils"

function notationDice(v, options = {}) {
  // Credit: https://dmitripavlutin.com/javascript-object-destructuring/
  const {
    allowAsterisk = true, // TODO: Change default to false and update usage.
  } = options

  // Supporting null values allows the form to be reset without throwing errors.
  // If a field is required, a separate validation check will handle that.
  if (v === null) {
    return true
  }
  if (typeof v === "number") {
    return true
  }
  if (typeof v === "string") {
    // Validate as though whitespace has already been removed from the string.
    // TODO: Ensure whitespace is always eventually removed from strings that use this validation.
    const vNoWhitespace = removeAllWhitespace(v)
    const allowedOperators = ["+"]

    if (vNoWhitespace.charAt(0) === "0") {
      // "Cannot start with 0"
      return false
    }

    if (vNoWhitespace.length > 7) {
      // 7 chars allows for up to "99D6+99".
      // "A maximum of 7 characters is allowed"
      return false
    }

    for (const operator of allowedOperators) {
      if (vNoWhitespace.charAt(0) === operator) {
        // `Cannot start with ${operator}`
        return false
      }
      if (vNoWhitespace.charAt(vNoWhitespace.length - 1) === operator) {
        // `Cannot end with ${operator}`
        return false
      }
    }

    if (allowAsterisk && vNoWhitespace === "*") {
      return true
    }

    if (!isNaN(Number(vNoWhitespace))) {
      // Value can be cast to type number.
      if (Number(vNoWhitespace) > 0) {
        // Accept integers greater than 0.
        return true
      }
    }

    // Regex tests.
    // This is as far as my regex skills can go in single expressions.
    // Beyond this we need to start testing substrings.
    // We allow both conventional & unconventional orientations in dice notation format
    // because not everyone is aware of the convention.
    // Unconventional orientation will be corrected on successful form submission.
    // TODO: Regex patterns use hardcoded operators. Either make them dynamic or hardcode operators elsewhere.
    // Conventional orientation. E.g. D3+3
    const regexA = RegExp(
      // TODO: Way too many capture groups in this pattern. Simplify.
      "^(([2-9])|([1-9][0-9]))?((D)([36]))?(([+])([1-9]|[1-9][0-9]))?$",
      "i"
    )
    // Unconventional orientation. E.g. 3+D3
    const regexB = RegExp(
      // TODO: Way too many capture groups in this pattern. Simplify.
      "^(([1-9])|([1-9][0-9]))?(([+]{1})(([2-9])|([1-9][0-9]))?(([D])([36])))?$",
      "i"
    )
    // Run tests against each regex.
    const regexAPass = regexA.test(vNoWhitespace)
    const regexBPass = regexB.test(vNoWhitespace)
    // Failing both should fail validation.
    if (!regexAPass && !regexBPass) {
      // vNoWhitespace has failed BOTH regex tests.
      return false
    }
    // Additional checks if regexA passed.
    if (regexAPass) {
      // [integer][operator][integer] would still pass but shouldn't.
      // Check for presence of an operator and inspect the values either side of it.
      for (const operator of allowedOperators) {
        if (vNoWhitespace.includes(operator)) {
          // Split vNoWhitespace at operator and inspect the substrings.
          const substrings = vNoWhitespace.split(operator)
          if (!substrings[0].toUpperCase().includes("D")) {
            // Value before operator does not contain "D".
            // Input value is therefore [integer][operator][integer] (because we passed the regexA test).
            return false
          }
        }
      }
    }
    // If we're still here then the input value is valid.
    return true
  } else {
    console.error("v must be a string or a number, instead got:", v)
    return false
  }
}

export { notationDice }
