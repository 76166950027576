<script setup>
import UcTextField from "@/components/UC/UcTextField.vue"
import { Icon } from "@iconify/vue"

defineProps({
  errorMessages: {
    type: Array,
    required: true,
  },
})

const model = defineModel({
  // Can't be required as it may receive a null value.
  type: [Number, String],
})
</script>

<template>
  <UcTextField
    v-model="model"
    :error-messages="errorMessages"
    hint="E.g: 2, D6, 3D3, D3+2"
    label="Attacks"
    required
    title="Accepts dice notation"
  >
    <template #append-inner>
      <Icon icon="mdi:dice-multiple" height="24" />
    </template>
  </UcTextField>
</template>
