<script setup>
import ResultsChart from "@/components/OneVsOne/Charts/ResultsChart"
import { computed } from "vue"
import { sortResultsArray } from "~/utils/utils"
import { cloneDeep } from "es-toolkit"
import { useGlobalStore } from "@/stores/global.js"

const props = defineProps({
  simResults: {
    type: Object,
    required: true,
  },
  stepResultsKey: {
    type: String,
    required: true,
  },
})

const store = useGlobalStore()

const layoutWrapperClasses = computed(() => {
  const noOfCharts = props.simResults.percentages.weaponResultsAll.length
  if (noOfCharts > 2) {
    return null
  }
  return `max-cols--${String(noOfCharts)}`
})

const simResultsCloned = computed(() => {
  return cloneDeep(props.simResults)
})

const chartData = computed(() => {
  const chartDataArray = []

  // Iterate through weapon results percentages.
  simResultsCloned.value.percentages.weaponResultsAll.forEach(
    (weapon, index) => {
      const stepResultsArray = weapon[props.stepResultsKey]
      sortResultsArray(stepResultsArray)
      // Create an object for each weapon.
      // Populate with data available during this iteration.
      chartDataArray[index] = {
        name: weapon.weaponName,
        stepResults: {
          percentages: stepResultsArray,
          // This data is not available during the current iteration,
          // so we just set up a placeholder property.
          percentagesCumulative: null,
        },
      }
    }
  )

  // Iterate through weapon results > percentagesCumulative.
  simResultsCloned.value.percentagesCumulative.weaponResultsAll.forEach(
    (weapon, index) => {
      // Fill in the missing percentagesCumulative data for each weapon in chartDataArray.
      chartDataArray[index].stepResults.percentagesCumulative =
        weapon[props.stepResultsKey]
    }
  )

  return chartDataArray
})

const showFootnoteWonky = computed(() => {
  return (
    props.stepResultsKey === "damageNotIgnored" &&
    store.getDefenderForSim.computed.modelCount > 1
  )
})
</script>

<template>
  <div class="layout-wrapper" :class="layoutWrapperClasses">
    <div v-for="weapon in chartData" :key="weapon.id" class="weapon-chart">
      <div class="h6 mb-6">{{ weapon.name }}</div>
      <ResultsChart
        :results-array-discrete="weapon.stepResults.percentages"
        :results-array-cumulative="weapon.stepResults.percentagesCumulative"
        :tab-id="props.stepResultsKey"
      />
    </div>
    <ul v-if="props.stepResultsKey === 'damageNotIgnored'" class="footnotes">
      <li>
        <small>
          "Damage not ignored" is capped at the total number of wounds in the
          defender profile.
        </small>
      </li>
      <li v-if="showFootnoteWonky">
        <small>
          <em>"My chart looks wonky!"</em> : This is can happen on "Damage not
          ignored" charts when the defender has >1 model types with varied
          defence stats.
        </small>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
// CSS grid would be soooo much easier here but the charts don't seem to like it.

.layout-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .weapon-chart {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media (min-width: 600px) {
    .weapon-chart {
      flex: 0 0 50%;
      max-width: 50%;
      &:nth-child(odd) {
        padding-right: 12px;
      }
      &:nth-child(even) {
        padding-left: 12px;
      }
    }
  }

  @media (min-width: 1264px) {
    .weapon-chart {
      flex: 0 0 33.33%;
      max-width: 33.3%;
      &:nth-child(1n) {
        padding-right: 12px;
      }
      &:nth-child(2n) {
        padding-right: 12px;
        padding-left: 12px;
      }
      &:nth-child(3n) {
        padding-left: 12px;
      }
    }
  }

  &.max-cols--1 {
    @media (min-width: 600px) {
      .weapon-chart {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  &.max-cols--2 {
    @media (min-width: 600px) {
      .weapon-chart {
        flex: 0 0 50%;
        max-width: 50%;
        &:nth-child(odd) {
          padding-right: 12px;
        }
        &:nth-child(even) {
          padding-left: 12px;
        }
      }
    }
  }
}

.footnotes {
  padding-left: 24px;
}
</style>
