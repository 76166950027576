<script setup>
import { computed, ref, toRaw, watch } from "vue"
import { useGlobalStore } from "@/stores/global"
import { configSims } from "~/utils/config"
import toRawDeep from "~/utils/toRawDeep.js"
import { useUserConfigSimsStore } from "@/stores/userConfigSims"

const storeUserConfigSims = useUserConfigSimsStore()
const store = useGlobalStore()
const { $toast } = useNuxtApp()

const simTimeoutId = ref()

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Using_web_workers
// https://vitejs.dev/guide/features.html#import-with-constructors

const worker = new Worker(
  new URL("/utils/sims/simulate.worker.ts", import.meta.url),
  {
    // We need to use the module type to use ES module imports within the worker.
    type: "module",
  }
)

const simTimeout = computed(() => {
  // Dynamic timeout value depending on how many sims we're trying to run.
  if (storeUserConfigSims.totalSims <= 100000) {
    return 40000
  }
  if (storeUserConfigSims.totalSims <= 500000) {
    return 80000
  }
  return 160000
})

watch(
  () => store.simResultsKeyCached,
  () => {
    if (store.simTriggerLast === "auto") {
      if (!storeUserConfigSims.autoRunSims) {
        return
      }
      if (
        storeUserConfigSims.autoRunSims &&
        storeUserConfigSims.totalSims > configSims.maxAutoRunSims
      ) {
        return
      }
    }
    // Explicitly set body height to its current actual height.
    // This prevents any scroll shift due to body height reduction while the sim is running.
    document.body.style.height = `${document.body.offsetHeight}px`
    // Set sim running.
    store.simulationRunning = true

    const message = {
      // Data that needs to be deep cloned can't be proxy objects so we use
      // toRaw() to convert them first. In some cases we use toRawDeep()
      // (custom function) to handle nested proxies. In other cases, values have
      // already had toRaw() applied and so don't need it re-applied here.
      attackContext: toRaw(store.getAttackContextForSim),
      attacker: toRaw(store.getAttackerForSim),
      defender: toRaw(store.getDefenderForSim),
      modifiersGlobal: toRawDeep(store.globalModifiersFilteredBySelected),
      modifiersProfileAttacker: store.getAttackerAbilitiesForSim,
      modifiersProfileDefender: store.getDefenderAbilitiesForSim,
      selectedWeapons: store.getWeaponsForSim,
      totalSims: storeUserConfigSims.totalSims,
    }
    worker.postMessage(message)
  },
  { immediate: true }
)

worker.onmessage = (e) => {
  clearTimeout(simTimeoutId.value)
  store.simulation = e.data
  store.endSim()
}

watch(
  () => store.simulationRunning,
  () => {
    // Stop the simulation if it doesn't complete within the timeout duration.
    if (store.simulationRunning) {
      simTimeoutId.value = setTimeout(() => {
        worker.terminate()
        const message = "Simulation took too long to complete (timeout)."
        store.simError = message
        store.simulationRunning = false
        store.simResultsReady = false
        store.simulation = null
        $toast.error(`Error: ${message}`)
        throw Error(message)
      }, simTimeout.value)
    }
  },
  { immediate: true }
)
</script>

<template>
  <div />
</template>
