<script setup>
import InputAttacks from "@/components/EditProfile/Inputs/weapon/InputAttacks"
import InputAp from "@/components/EditProfile/Inputs/weapon/InputAp"
import InputInt from "@/components/EditProfile/Inputs/shared/InputInt.vue"
import InputWeaponStrength from "@/components/EditProfile/Inputs/weapon/InputWeaponStrength"
import InputDamage from "@/components/EditProfile/Inputs/weapon/InputDamage"
import useVuelidate from "@vuelidate/core"
import { between, integer, required } from "@vuelidate/validators"
import { notationDice } from "~/utils/crud/vuelidateRules"
import { computed, onMounted, ref, watch } from "vue"
import { useVuelidateErrorsWeapon } from "@/composables/useVuelidateErrorsWeapon.js"

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(["update:modelValue"])

const weapon = ref({
  attacks: null,
  bs: null,
  strength: null,
  ap: 0,
  damage: null,
})

const validations = computed(() => ({
  weapon: {
    ap: { between: between(-6, 6), integer, required },
    bs: {
      between: between(2, 6),
      integer,
      required,
    },
    attacks: { notationDice, required },
    damage: { required, notationDice },
    strength: { notationDice, required },
  },
}))

const v$ = useVuelidate(validations, { weapon })
const { errorsAp, errorsDamage, errorsStrength, errorsAttacks } =
  useVuelidateErrorsWeapon(v$)

const weaponShared = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit("update:modelValue", v)
  },
})

function errorsBs() {
  const errors = []
  if (!v$.value.weapon.bs.$dirty) return errors
  v$.value.weapon.bs.integer.$invalid && errors.push("Must be a number")
  v$.value.weapon.bs.between.$invalid && errors.push("Must be between 2 and 6")
  v$.value.weapon.bs.required.$invalid && errors.push("Value required")
  return errors
}

watch(
  () => weapon.value,
  (newValue) => {
    weaponShared.value = newValue
  }
)

onMounted(() => {
  weapon.value = { ...props.modelValue }
})
</script>

<template>
  <div class="weapon-inputs mt-4">
    <InputAttacks
      v-model="weapon.attacks"
      :error-messages="errorsAttacks()"
      class="input-weapon-attacks"
      data-cy="input-weapon-attacks"
      @blur="v$.weapon.attacks.$touch()"
      @input="v$.weapon.attacks.$touch()"
    />

    <InputInt
      v-model="weapon.bs"
      :error-messages="errorsBs()"
      data-cy="input-weapon-bs"
      hint="Ballistic/Weapon skill"
      label="BS (WS)"
      required
      @blur="v$.weapon.bs.$touch()"
      @input="v$.weapon.bs.$touch()"
    />

    <InputWeaponStrength
      v-model="weapon.strength"
      :error-messages="errorsStrength()"
      data-cy="input-weapon-strength"
      @blur="v$.weapon.strength.$touch()"
      @input="v$.weapon.strength.$touch()"
    />

    <InputAp
      v-model="weapon.ap"
      :error-messages="errorsAp()"
      data-cy="input-weapon-ap"
      @blur="v$.weapon.ap.$touch()"
      @input="v$.weapon.ap.$touch()"
    />

    <InputDamage
      v-model="weapon.damage"
      :error-messages="errorsDamage()"
      data-cy="input-weapon-damage"
      @blur="v$.weapon.damage.$touch()"
      @input="v$.weapon.damage.$touch()"
    />
  </div>
</template>

<style lang="scss" scoped>
.weapon-inputs {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  @media (min-width: 40rem) {
    grid-template-columns: repeat(5, 1fr);
  }
}
</style>
