<script setup>
import ModifierBuilderGlobal from "@/components/EditModifier/ScopedBuilders/ModifierBuilderGlobal.vue"
import { useGlobalStore } from "@/stores/global"
import { computed } from "vue"
import { Icon } from "@iconify/vue"

const store = useGlobalStore()

const panelLabel = computed(() => {
  const active = store.globalModifiersCount.active
  const total = store.globalModifiersCount.total
  return total ? `Global modifiers: ${active}/${total}` : "Global modifiers"
})
</script>

<template>
  <v-expansion-panel class="expansion-panel-global-modifiers">
    <v-expansion-panel-title data-cy="btn-toggle-exp-panel-global-modifiers">
      <div class="header-inner">
        <div class="header-icon">
          <Icon icon="mdi:tune-vertical" height="22" />
        </div>
        <div class="header-label">
          {{ panelLabel }}
        </div>
      </div>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <ModifierBuilderGlobal class="pt-2" />
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<style lang="scss" scoped>
.header-inner {
  display: flex;
  align-items: center;
}
.header-icon {
  flex: 0 1 auto;
  margin-right: 24px;
}
.header-label {
  flex: 1 1 auto;
}
</style>
