<script setup>
import { computed, ref, watch } from "vue"
import { onKeyStroke } from "@vueuse/core"
import UcBtn from "@/components/UC/UcBtn.vue"
import UcTextField from "@/components/UC/UcTextField.vue"
import { Icon } from "@iconify/vue"

const props = defineProps({
  modelValue: {
    required: true,
    type: [Object, null],
  },
  items: {
    type: Array,
    required: true,
  },
  itemText: {
    default: "text",
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  filterInputLabel: {
    type: String,
    default: "Select item",
  },
})

const emit = defineEmits(["update:modelValue", "change"])

const activeItemIndex = ref(0)
const dialogueOpen = ref(false)
const filterString = ref(null)
const resultsWrapperEl = ref(null)
const resultsEl = ref(null)

const itemsFiltered = computed(() => {
  let returnArray = []
  if (!filterString.value) {
    returnArray = props.items
  } else {
    returnArray = props.items.filter((item) => {
      return item[props.itemText]
        .toLowerCase()
        .includes(filterString.value.toLowerCase())
    })
  }
  return returnArray
})

function selectResult() {
  const result = itemsFiltered.value[activeItemIndex.value]
  emit("update:modelValue", result)
  emit("change", result)
  dialogueOpen.value = false
}

onKeyStroke("ArrowDown", (e) => {
  if (dialogueOpen.value) {
    e.preventDefault()
    activeItemIndex.value = Math.min(
      activeItemIndex.value + 1,
      itemsFiltered.value.length - 1
    )
    checkResultVisibility("ArrowDown")
    delayAllowMouseEnterSetActive()
  }
})
onKeyStroke("ArrowUp", (e) => {
  if (dialogueOpen.value) {
    e.preventDefault()
    activeItemIndex.value = Math.max(activeItemIndex.value - 1, 0)
    checkResultVisibility("ArrowUp")
    delayAllowMouseEnterSetActive()
  }
})
onKeyStroke("Enter", (e) => {
  if (dialogueOpen.value) {
    e.preventDefault()
    selectResult()
  }
})

function checkResultVisibility(key) {
  if (!resultsWrapperEl.value || !resultsEl.value) {
    return
  }

  // Get references to various elements and their positions/dimensions.
  const resultsWrapperElBcr = resultsWrapperEl.value.getBoundingClientRect()
  const resultEl = resultsEl.value.children[activeItemIndex.value]
  const resultElBcr = resultEl.getBoundingClientRect()
  const resultHeight = resultElBcr.height

  // Check keystroke and then compare elements.
  if (key === "ArrowDown") {
    if (resultsWrapperElBcr.bottom < resultElBcr.bottom) {
      // Scroll down 1 result.
      resultsWrapperEl.value.scrollBy(0, resultHeight)
    }
  }
  if (key === "ArrowUp") {
    if (resultsWrapperElBcr.top > resultElBcr.top) {
      // Scroll up 1 result.
      resultsWrapperEl.value.scrollBy(0, 0 - resultHeight)
    }
  }
}

// Prevent mouseEnter events setting the active result index
// if the result was moved to the mouse cursor by an up/down keystroke.
let allowMouseEnterSetActive = true
let timeout

function delayAllowMouseEnterSetActive() {
  allowMouseEnterSetActive = false
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    allowMouseEnterSetActive = true
  }, 400)
}

function onMouseenterResult(index) {
  if (allowMouseEnterSetActive) {
    activeItemIndex.value = index
  }
}

function onClickCancel() {
  dialogueOpen.value = false
}

function onClickResult() {
  selectResult()
}

function openDialog() {
  if (!props.disabled) {
    dialogueOpen.value = true
  }
}

watch(dialogueOpen, (newValue) => {
  // Open
  if (newValue) {
    // Clear filter input.
    filterString.value = null
    // Mark item 0 as active.
    activeItemIndex.value = 0
  }
})

watch(filterString, () => {
  activeItemIndex.value = 0
})
</script>

<template>
  <div>
    <UcBtn :disabled="disabled" size="small" @click="openDialog">
      <Icon
        icon="mdi:magnify"
        height="18"
        :inline="true"
        class="mr-1"
        title="Cancel (Esc)"
      />{{ label }}
    </UcBtn>

    <v-dialog
      v-model="dialogueOpen"
      max-width="700"
      :retain-focus="false"
      :fullscreen="$vuetify.display.mobile"
      scrim="black"
    >
      <div class="outer-wrapper">
        <div class="header">
          <UcTextField
            v-model="filterString"
            :label="filterInputLabel"
            placeholder="Type to filter results"
            outlined
            hide-details
            autofocus
            clearable
            class="filter-input"
            data-cy="input-entity-filter"
            autocomplete="off"
          >
            <template #append-inner>
              <Icon icon="mdi:magnify" height="18" />
            </template>
          </UcTextField>
          <Icon
            icon="mdi:close"
            height="18"
            class="close-btn"
            @click="onClickCancel"
          />
        </div>

        <div
          ref="resultsWrapperEl"
          class="results-wrapper"
          data-cy="entity-results"
        >
          <div v-if="itemsFiltered.length" ref="resultsEl" class="results">
            <div
              v-for="(item, index) in itemsFiltered"
              :key="`${item[itemText]}_${item.id}`"
              class="result"
              :class="{ active: index === activeItemIndex }"
              @mouseenter="onMouseenterResult(index)"
              @click="onClickResult(item)"
            >
              {{ item[itemText] }}
            </div>
          </div>
          <div v-else class="no-results">No results for current search.</div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
@import "vuetify/settings";

.outer-wrapper {
  background-color: #1e1e1e;
  padding: 12px 12px 6px 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  // Try to adapt to potential virtual keyboard.
  // TODO: This seems to work OK on Firefox on Android but not Chrome on Android.
  max-height: 100vh;
  @media (hover: hover) {
    // Device is not multi-touch therefore there won't be a virtual keyboard.
    max-height: 50vh;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding: 32px 32px 16px 32px;
  }
}

.close-btn {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 30px;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    top: 12px;
    right: 8px;
  }
}

.filter-input {
  border-radius: 4px;
  flex: 0 1 auto;
  margin-bottom: 8px;
  margin-right: 32px;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-right: 0;
  }
}

.results-wrapper {
  border: 1px solid rgba(white, 0.24);
  border-radius: 4px;
  margin-bottom: 12px;
  flex: 1 1 auto;
  overflow-y: scroll;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-bottom: 16px;
  }
}

.result {
  padding: 12px 16px;
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: rgba(white, 0.08);

    &:active {
      // Alpha value mimics those used elsewhere in Vuetify.
      background-color: rgba(white, 0.24);
    }
  }
}

.no-results {
  padding: 12px 16px;
}
</style>
