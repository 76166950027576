import { defineStore } from "pinia"
import { ref } from "vue"

export const useEditProfileStore = defineStore(
  "editProfile",
  () => {
    const alertExplainScopeProfile = ref(true)
    const alertExplainScopeWeapon = ref(true)
    const intendedRole = ref(null)
    const limitProfileFormAccess = ref(false)

    return {
      alertExplainScopeProfile,
      alertExplainScopeWeapon,
      intendedRole,
      limitProfileFormAccess,
    }
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
      pick: ["alertExplainScopeProfile", "alertExplainScopeWeapon"],
    },
  }
)
