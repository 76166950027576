<script setup>
import { useGlobalStore } from "@/stores/global"
import { configProfiles } from "~/utils/config"
import UcBtn from "@/components/UC/UcBtn.vue"
import { computed } from "vue"
import { useEditProfileStore } from "@/stores/editProfile"

const props = defineProps({
  label: {
    type: String,
    default: "New profile",
  },
  role: {
    type: String,
    default: null,
  },
  size: {
    type: [Number, String],
    default: "default",
  },
})

const store = useGlobalStore()
const storeEditProfile = useEditProfileStore()

const addProfileDisabled = computed(() => {
  if (store.readOnlyProfiles) {
    return true
  }
  return store.profilesCount >= configProfiles.maxProfileCount
})

function onClickButton() {
  storeEditProfile.limitProfileFormAccess = true
  storeEditProfile.intendedRole = props.role
}
</script>

<template>
  <UcBtn
    :key="store.profilesCount"
    :disabled="addProfileDisabled"
    :size="size"
    @click="onClickButton"
  >
    {{ label }}
  </UcBtn>
</template>
