import { toRaw } from "vue"

// Credit: https://stackoverflow.com/a/77022014
export default function toRawDeep(observed) {
  const val = toRaw(observed)

  if (Array.isArray(val)) {
    return val.map(toRawDeep)
  }

  if (val === null) return null

  if (typeof val === "object") {
    const entries = Object.entries(val).map(([key, val]) => [
      key,
      toRawDeep(val),
    ])

    return Object.fromEntries(entries)
  }

  return val
}
