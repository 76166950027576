import { cloneDeep } from "es-toolkit"

export default function cleanWeapon(incomingWeapon, options = {}) {
  // console.log("incomingWeapon", incomingWeapon)

  // Assign constants to `options` properties and set defaults.
  // Credit: https://dmitripavlutin.com/javascript-object-destructuring/
  const {
    // Whether to directly mutate the incoming modifier or not.
    mutate = false,
  } = options

  const weapon = mutate ? incomingWeapon : cloneDeep(incomingWeapon)

  // Delete root level properties with null values.
  Object.keys(weapon).forEach((key) => {
    if (weapon[key] === null) {
      delete weapon[key]
    }
  })

  // Delete empty abilities.
  if (typeof weapon.abilities !== "undefined") {
    if (!weapon.abilities.length) {
      delete weapon.abilities
    }
  }

  // Delete abilityIdNext if not needed.
  if (typeof weapon.abilities === "undefined") {
    delete weapon.abilityIdNext
  }

  weapon.updated = Date.now()

  // console.log("weapon", weapon)

  return weapon
}
