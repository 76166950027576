<script setup>
import { computed } from "vue"
import { Icon } from "@iconify/vue"

const props = defineProps({
  color: {
    type: String,
    default: "blue-grey-darken-3",
  },
  density: {
    type: String,
    default: "default",
  },
  closable: {
    type: Boolean,
    default: false,
  },
  elevation: {
    type: [Number, String],
    default: undefined,
  },
  variant: {
    type: String,
    default: "flat",
  },
  type: {
    type: String,
    default: "info",
  },
  modelValue: {
    type: Boolean,
  },
})

const emit = defineEmits(["update:modelValue"])

const localValue = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit("update:modelValue", v)
  },
})
</script>

<template>
  <v-alert
    v-model="localValue"
    :color="color"
    :density="density"
    :closable="closable"
    :elevation="elevation"
    :variant="variant"
    :type="type"
  >
    <slot/>
    <template v-if="closable" #close>
      <v-btn
        rounded="xl"
        variant="tonal"
        color="white"
        title="Close"
        @click="localValue = false"
      >
        <Icon icon="mdi:close" height="16" />
      </v-btn>
    </template>
  </v-alert>
</template>
