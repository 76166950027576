<script setup>
import SvgLogoShieldNodeCrestDice from "@/assets/images/vector/logo-shield-node-crest-dice.svg"
import { useGlobalStore } from "@/stores/global"

const runtimeConfig = useRuntimeConfig()
const store = useGlobalStore()
const user = useSupabaseUser()
const { auth } = useSupabaseClient()
const { $toast } = useNuxtApp()

const userLoggedIn = computed(() => user.value !== null)
const userNotLoggedIn = computed(() => user.value === null)

const envPackageVersion = runtimeConfig.public.clientVersion

async function onClickLogOut() {
  try {
    // { scope: "local" } signs the user out of the current session only.
    // Without this option, the user would be signed out of all sessions
    // (on all devices).
    // https://supabase.com/docs/guides/auth/signout?queryGroups=language&language=js#sign-out-and-scopes
    const { error } = await auth.signOut({ scope: "local" })
    if (error) throw error
    user.value = null
    $toast.success("You have now been logged out.", {
      timeout: 2000,
    })
  } catch (error) {
    console.error(error)
    $toast.error("Error logging out.")
  } finally {
    // Navigate to "/" if current route is protected.
    const route = useRoute()
    if (route.path.startsWith("/account")) {
      navigateTo("/")
    }
  }
}
</script>

<template>
  <v-navigation-drawer
    v-model="store.appNavDrawer"
    temporary
    location="right"
    :scrim="true"
    floating
    class="bg-grey-darken-4"
  >
    <v-list>
      <v-list-item to="/" exact>
        <v-list-item-title>Crunch</v-list-item-title>
      </v-list-item>

      <v-list-item to="/manage-profiles">
        <v-list-item-title>Manage profiles</v-list-item-title>
      </v-list-item>

      <v-list-item to="/about">
        <v-list-item-title>About</v-list-item-title>
      </v-list-item>

      <v-list-item to="/support-unitcrunch">
        <v-list-item-title>Support UnitCrunch</v-list-item-title>
      </v-list-item>

      <v-list-item to="/faq">
        <v-list-item-title>FAQ</v-list-item-title>
      </v-list-item>

      <v-list-item to="/changelog">
        <v-list-item-title>Changelog</v-list-item-title>
      </v-list-item>

      <v-list-item to="/feedback">
        <v-list-item-title>Feedback</v-list-item-title>
      </v-list-item>

      <v-list-item to="/privacy-policy">
        <v-list-item-title>Privacy policy</v-list-item-title>
      </v-list-item>
    </v-list>

    <!-- TODO: Remove condition before auth goes live. -->
    <template v-if="runtimeConfig.public.appEnvironment !== 'prod'">
      <v-divider />

      <v-list>
        <v-list-subheader>Account</v-list-subheader>

        <template v-if="userNotLoggedIn">
          <v-list-item to="/login">
            <v-list-item-title>Log in</v-list-item-title>
          </v-list-item>

          <v-list-item to="/sign-up">
            <v-list-item-title>Sign up</v-list-item-title>
          </v-list-item>
        </template>

        <template v-if="userLoggedIn">
          <v-list-item to="/account">
            <v-list-item-title>Manage account</v-list-item-title>
          </v-list-item>

          <v-list-item @click="onClickLogOut">
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </template>

    <DevOnly>
      <v-divider />
      <v-list>
        <v-list-subheader>Dev</v-list-subheader>
        <v-list-item to="/dev/modifier-sandbox?scope=weapon">
          <v-list-item-title>Modifier sandbox</v-list-item-title>
        </v-list-item>

        <v-list-item to="/dev/migrate-preset-modifiers">
          <v-list-item-title>Migrate preset modifiers</v-list-item-title>
        </v-list-item>

        <v-list-item to="/dev/migrate-preset-profiles">
          <v-list-item-title>Migrate preset profiles</v-list-item-title>
        </v-list-item>
      </v-list>
    </DevOnly>

    <v-divider />

    <v-list>
      <v-list-subheader>Social</v-list-subheader>
      <v-list-item
        href="https://www.reddit.com/r/UnitCrunch/"
        target="_blank"
        rel="noopener"
      >
        r/UnitCrunch
      </v-list-item>
    </v-list>

    <v-divider />

    <v-list disabled>
      <v-list-item>
        <v-list-item-title class="text-caption"
          >Version: {{ envPackageVersion }}
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider />

    <div class="logo-wrapper">
      <SvgLogoShieldNodeCrestDice
        class="logo"
        :font-controlled="false"
        aria-hidden="true"
      />
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  justify-content: center;
  padding: 32px 16px;
}

.logo {
  width: 50%;
  max-width: 100px;
}
</style>
