<script setup>
import UcBtn from "@/components/UC/UcBtn.vue"
import { useGlobalStore } from "@/stores/global"
import LogotypeShield from "@/assets/images/vector/logotype-shield.svg"

const store = useGlobalStore()

function onNavIconClick() {
  store.appNavDrawer = !store.appNavDrawer
}
</script>

<template>
  <v-app-bar absolute density="compact" flat class="mb-6">
    <v-toolbar-title>
      <NuxtLink to="/" class="logo-link" aria-label="UnitCrunch">
        <LogotypeShield
          class="logotype-shield"
          :font-controlled="false"
          aria-label="UnitCrunch"
        />
      </NuxtLink>
    </v-toolbar-title>

    <UcBtn
      to="/"
      exact
      variant="text"
      size="small"
      rounded="0"
      class="d-none d-sm-flex"
      data-cy="btn-app-bar-crunch"
    >
      Crunch
    </UcBtn>

    <UcBtn
      to="/manage-profiles"
      variant="text"
      size="small"
      rounded="0"
      class="d-none d-sm-flex"
      data-cy="btn-app-bar-manage-profiles"
    >
      Manage profiles
    </UcBtn>

    <v-app-bar-nav-icon
      aria-label="menu"
      data-cy="btn-app-bar-menu"
      @click="onNavIconClick"
    />
  </v-app-bar>
</template>

<style lang="scss" scoped>
.logo-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

.logotype-shield {
  height: 18px;
}
</style>
