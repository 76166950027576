// NOTE: This is a snapshot of the main cleanModifier() function
// to ensure it always works as expected for the 0.39.0 migration.

import { cloneDeep } from "es-toolkit"

export default function cleanModifier(incomingModifier, options = {}) {
  // console.log("incomingModifier", incomingModifier)

  // Assign constants to `options` properties and set defaults.
  // Credit: https://dmitripavlutin.com/javascript-object-destructuring/
  const {
    // Whether to directly mutate the incoming modifier or not.
    mutate = false,
  } = options

  const modifier = mutate ? incomingModifier : cloneDeep(incomingModifier)

  // Delete root level properties with null values.
  Object.keys(modifier).forEach((key) => {
    if (modifier[key] === null) {
      delete modifier[key]
    }
  })

  // Delete root level objects that aren't required for the current modifier type.
  switch (modifier.type) {
    case "modifyAbsolute":
      delete modifier.effect
      delete modifier.override
      delete modifier.reRoll
      break
    case "modifyRelative":
      delete modifier.effect
      delete modifier.override
      delete modifier.reRoll
      break
    case "overrideReqs":
      delete modifier.effect
      delete modifier.reRoll
      break
    case "reRoll":
      delete modifier.effect
      delete modifier.override
      break
    case "conditionalEffect":
      delete modifier.override
      delete modifier.reRoll
      break
    case "special":
      delete modifier.effect
      delete modifier.override
      delete modifier.reRoll
      break
  }

  // Delete application properties that aren't required outside the "Edit modifier" form.
  if (typeof modifier.application !== "undefined") {
    if (typeof modifier.application.typesByScope !== "undefined") {
      delete modifier.application.types
    }
  }

  if (typeof modifier.effect !== "undefined") {
    if (
      typeof modifier.effect.type !== "undefined" &&
      modifier.effect.type &&
      typeof modifier.effect.type.relevantConditionValues !== "undefined"
    ) {
      delete modifier.effect.type.relevantConditionValues
    }
    // Delete effect properties with null values.
    Object.keys(modifier.effect).forEach((key) => {
      if (modifier.effect[key] === null) {
        delete modifier.effect[key]
      }
    })
  }

  // Delete redundant conditions.
  if (typeof modifier.conditions !== "undefined") {
    const conditionIndicesToDelete = []
    modifier.conditions.forEach((condition, index) => {
      // Keywords excluded/only
      if (condition.type.startsWith("keywords")) {
        if (condition.data.keywords.length === 0) {
          // Note condition index for removal after this forEach loop.
          conditionIndicesToDelete.push(index)
        }
      }
    })
    // Delete conditions marked for removal.
    conditionIndicesToDelete.forEach((index) => {
      modifier.conditions.splice(index, 1)
    })
    // Delete conditions property altogether if it's now empty.
    if (modifier.conditions.length === 0) {
      delete modifier.conditions
    }
  }

  // Delete reRoll properties with null values.
  if (typeof modifier.reRoll !== "undefined") {
    Object.keys(modifier.reRoll).forEach((key) => {
      if (modifier.reRoll[key] === null) {
        delete modifier.reRoll[key]
      }
    })
  }

  modifier.updated = Date.now()

  // console.log("modifier", modifier)

  return modifier
}
