<script setup>
import { vMaska } from "maska/vue"
import UcTextField from "@/components/UC/UcTextField.vue"
import { computed } from "vue"

defineProps({
  errorMessages: {
    type: Array,
    required: true,
  },
})

const model = defineModel({
  // Can't be required as it may receive a null value.
  type: [Number, String],
})

const maskaOptions = computed(() => {
  const options = {
    mask: "N",
    tokens: {
      N: {
        pattern: /[0-9]/,
      },
    },
  }
  if (model.value !== "0" && model.value !== "-0") {
    options.mask = "-N"
  }
  return options
})
</script>

<template>
  <UcTextField
    v-model="model"
    v-maska="maskaOptions"
    :error-messages="errorMessages"
    label="AP"
    hint="Armour penetration"
    max="0"
    min="-6"
    required
    type="number"
  />
</template>
