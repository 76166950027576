<script setup>
import StepCharts from "@/components/OneVsOne/Charts/StepCharts"
import { useGlobalStore } from "@/stores/global"
import { Tabs, Tab } from "vue3-tabs-component"
import "assets/scss/overrides/vue3-tabs-component.scss"
import { computed, nextTick, onMounted, provide, ref } from "vue"

const store = useGlobalStore()
const currentTab = ref(null)
provide("currentTab", currentTab)

onMounted(async () => {
  await nextTick()
  // Remove inline height style from body.
  document.body.style.height = null
})

function onActiveTabChanged(e) {
  chartResultsTabs.value.find((tab) => {
    if (tab.text === e.tab.name) {
      currentTab.value = tab.value
    }
  })
}

const chartResultsTabs = computed(() => {
  const allTabs = [
    { text: "Hits", value: "hits" },
    { text: "Wounds dealt", value: "woundsDealt" },
    { text: "Unsaved wounds", value: "unsavedWounds" },
    { text: "Damage dealt (inc. MW)", value: "damageDealt" },
    { text: "Mortal wounds", value: "mortalWounds" },
    { text: "Damage not ignored", value: "damageNotIgnored" },
  ]

  // Determine if any step results contain unhelpful data and hide tabs accordingly.

  // Omit the "Mortal wounds" tab if ALL weapons return a results map containing a single key of `0`.
  let showMortalWounds = false
  store.simulation.simResults.count.weaponResultsAll.forEach((weapon) => {
    if (weapon.mortalWounds instanceof Map) {
      if (weapon.mortalWounds.size > 1) {
        showMortalWounds = true
      } else {
        if (Number(weapon.mortalWounds?.keys().next().value) > 0) {
          showMortalWounds = true
        }
      }
    }
  })

  // Filter out tabs that would just display unhelpful data.
  const filteredTabs = allTabs.filter((tab) => {
    if (tab.value === "mortalWounds") {
      return showMortalWounds
    }
    if (tab.value === "damageNotIgnored") {
      return store.showDamageNotIgnored()
    }
    return true
  })

  return filteredTabs.map((tab) => {
    if (tab.value === "damageDealt" && !showMortalWounds) {
      tab.text = "Damage dealt"
    }
    return tab
  })
})
</script>

<template>
  <div class="bg-grey-darken-4">
    <Tabs
      :options="{
        storageKey: 'unitcrunch.tabs.weapon-results',
        useUrlFragment: false,
      }"
      @changed="onActiveTabChanged"
    >
      <Tab
        v-for="tab in chartResultsTabs"
        :key="tab.text"
        :name="tab.text"
        class="pa-6"
      >
        <StepCharts
          :key="store.simulation.simId"
          :sim-results="store.simulation.simResults"
          :step-results-key="tab.value"
        />
      </Tab>
    </Tabs>
  </div>
</template>
