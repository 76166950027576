<script setup>
import { useGlobalStore } from "@/stores/global"
import { configProfiles } from "~/utils/config"
import { aliasesFormatted, sanitiseClassName } from "~/utils/utils"
import { computed } from "vue"
import { Icon } from "@iconify/vue"

const store = useGlobalStore()

// Weapons table

const weaponsTableData = computed(() => {
  return store.currentAttackerWeaponsFiltered
})

const weaponsTableHeaders = computed(() => {
  const headers = [
    { title: "Weapon", key: "nameUnique", sortable: false },
    { title: "Count", key: "countPerUnit", sortable: false },
    { title: "A", key: "attacks", sortable: false },
    { title: "S", key: "strength", sortable: false },
    { title: "AP", key: "ap", sortable: false },
    { title: "D", key: "damage", sortable: false },
  ]

  // Add "WS" header if required.
  if (store.attackContext.attackType === "Melee") {
    headers.splice(3, 0, {
      title: "WS",
      key: "ws",
      sortable: false,
    })
  }

  // Add "BS" header if required.
  if (store.attackContext.attackType === "Ranged") {
    headers.splice(3, 0, {
      title: "BS",
      key: "bs",
      sortable: false,
    })
  }

  // Add "Abilities" header if required.
  let showAbilitiesHeader = false

  for (const weapon of weaponsTableData.value) {
    if (weapon.type !== store.attackContext.attackType) {
      continue
    }
    if (weapon?.abilities?.length > 0) {
      // At least one of this attacker's weapons has an ability.
      showAbilitiesHeader = true
    }
  }

  if (showAbilitiesHeader) {
    headers.push({ title: "Abilities", key: "abilities", sortable: false })
  }

  return headers
})

const showWeaponSelect = computed(() => {
  if (store.readOnlyProfiles) {
    return false
  }
  return Boolean(weaponsTableData.value.length > 1)
})

const selectedWeapons = computed({
  get() {
    if (store.attackContext.attackType === "Melee") {
      return store.currentAttacker.weaponsSelected.melee
    } else {
      return store.currentAttacker.weaponsSelected.ranged
    }
  },
  set(selectedWeaponsIds) {
    store.setCurrentAttackerWeaponsSelected({
      profileId: store.currentAttacker.id,
      selectedWeaponsIds,
    })
  },
})

// Abilities table

const abilitiesTableData = computed(() => {
  return store.currentAttackerAbilitiesFiltered
})

const abilitiesTableHeaders = computed(() => {
  const headers = []

  if (store.currentAttacker.units.length > 1) {
    headers.push({
      title: "Unit",
      key: "unit",
      sortable: false,
    })
  }

  headers.push({
    title: "Profile ability",
    key: "description",
    sortable: false,
  })

  // Add "Alias" header if required.
  let showAliasHeader = false
  abilitiesTableData.value.forEach((ability) => {
    if (ability.aliases.length && ability.aliasActive) {
      // At least one of this profile's abilities has an alias.
      showAliasHeader = true
    }
  })
  if (showAliasHeader) {
    headers.push({ title: "Alias", key: "aliases", sortable: false })
  }

  if (store.currentAttacker.units.length > 1) {
    headers.push({
      title: "Shared",
      key: "sharedWithAllUnits",
      sortable: false,
    })
  }

  return headers
})

const selectedAbilities = computed({
  get() {
    return store.currentAttackerAbilitiesSelectedFiltered.map(
      (ability) => ability.id
    )
  },
  set(selectedAbilitiesIds) {
    store.setCurrentAttackerAbilitiesSelected({
      profileId: store.currentAttacker.id,
      selectedAbilitiesIds,
    })
  },
})

function displayWeaponAbility(ability) {
  if (ability.aliases.length && ability.aliasActive) {
    return ability.aliases[0].name
  }
  return ability.description
}

function weaponAbilityClasses(ability) {
  if (ability.aliases.length && ability.aliasActive) {
    return "alias"
  } else {
    return "description"
  }
}

function rowPropsAbilities(data) {
  return { class: sanitiseClassName(data.item.description) }
}

function rowPropsWeapons(data) {
  return { class: sanitiseClassName(data.item.nameUnique) }
}

function weaponBs(weapon) {
  if (
    typeof weapon.bs === "undefined" ||
    weapon.bs === null ||
    weapon.bs === ""
  ) {
    return "N/A"
  }
  return `${weapon.bs}+`
}

function weaponWs(weapon) {
  if (
    typeof weapon.ws === "undefined" ||
    weapon.ws === null ||
    weapon.ws === ""
  ) {
    return "N/A"
  }
  return `${weapon.ws}+`
}
</script>

<template>
  <div class="component-wrapper">
    <div class="font-weight-bold mt-6 mb-2">
      {{ store.attackContext.attackType }} weapons
    </div>

    <v-data-table
      v-if="weaponsTableData.length"
      v-model="selectedWeapons"
      :show-select="showWeaponSelect"
      :headers="weaponsTableHeaders"
      :items="weaponsTableData"
      item-key="id"
      data-cy="table-attacker-weapons"
      :row-props="rowPropsWeapons"
      :items-per-page="configProfiles.maxWeaponCount"
    >
      <template #item.bs="{ item }">
        {{ weaponBs(item) }}
      </template>

      <template #item.ws="{ item }">
        {{ weaponWs(item) }}
      </template>

      <template #item.abilities="{ item }">
        <ul class="weapon-abilities">
          <li
            v-for="ability in item.abilities"
            :key="ability.id"
            class="weapon-ability"
            :class="weaponAbilityClasses(ability)"
          >
            {{ displayWeaponAbility(ability) }}
          </li>
        </ul>
      </template>
      <!-- Hides pagination -->
      <template #bottom />
    </v-data-table>

    <template v-if="abilitiesTableData.length">
      <div class="font-weight-bold mt-4 mb-2">Relevant unit abilities</div>

      <v-data-table
        v-model="selectedAbilities"
        show-select
        :disabled="store.readOnlyProfiles"
        :headers="abilitiesTableHeaders"
        :items="abilitiesTableData"
        item-key="id"
        data-cy="table-attacker-abilities"
        :row-props="rowPropsAbilities"
      >
        <template #item.unit="{ item }">
          {{ item.computed.sourceUnit.name }}
        </template>
        <template #item.aliases="{ item }">
          <div class="aliases">
            {{ aliasesFormatted(item) }}
          </div>
        </template>
        <template #item.sharedWithAllUnits="{ item }">
          <Icon v-if="item.sharedWithAllUnits" icon="mdi:check" height="18" />
          <template v-else>—</template>
        </template>
        <!-- Hides pagination -->
        <template #bottom />
      </v-data-table>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.unit-name-icon {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
.weapon-abilities {
  margin: 6px 0 0;
  list-style: none;
  padding: 0;
  li {
    margin-bottom: 4px;
  }
  .alias {
    text-transform: uppercase;
  }
}
.aliases {
  text-transform: uppercase;
}
</style>
