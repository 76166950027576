<script setup>
import EditableText from "@/components/Common/EditableText"
import UcBtn from "@/components/UC/UcBtn.vue"
import { Icon } from "@iconify/vue"

defineProps({
  disableRemove: {
    type: Boolean,
    default: false,
  },
  id: {
    type: Number,
    required: true,
  },
  title: {
    default: "",
    type: String,
  },
})

const emit = defineEmits(["remove", "titleUpdated"])

function handledUpdateTitle(newTitle) {
  emit("titleUpdated", newTitle)
}
</script>

<template>
  <div class="pane">
    <div class="pane__header">
      <div class="pane__title">
        <EditableText v-if="title" :text="title" @update="handledUpdateTitle" />
      </div>
      <UcBtn
        class="pane__btn-remove"
        elevation="0"
        size="x-small"
        title="Remove"
        :disabled="disableRemove"
        @click="$emit('remove')"
      >
        <Icon icon="mdi:close" />
      </UcBtn>
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.pane {
  background-color: rgba(white, 0.02);
  padding: 16px 16px 0 16px;
  border-radius: 4px;
}

.pane__header {
  display: flex;
}

.pane__title {
  flex-grow: 1;
  margin-right: 16px;
}

.btn-remove {
  min-height: 24px;
  min-width: 24px;
}
</style>
