<script setup>
import Loading from "@/components/Common/Loading.vue"
import { aliasesFormatted, sanitiseClassName } from "~/utils/utils"
import UcBtn from "@/components/UC/UcBtn.vue"
import UcAlert from "@/components/UC/UcAlert.vue"
import { computed, defineAsyncComponent, ref } from "vue"
import { useGlobalStore } from "@/stores/global"
import { Icon } from "@iconify/vue"

const EditModifier = defineAsyncComponent({
  loader: () => import("@/components/EditModifier/EditModifier.vue"),
  loadingComponent: Loading,
})
const DialogCommon = defineAsyncComponent(
  () => import("@/components/Common/DialogCommon.vue")
)
const SearchModifiers = defineAsyncComponent({
  loader: () => import("@/components/EditModifier/SearchModifiers.vue"),
  loadingComponent: Loading,
})

const props = defineProps({
  heading: {
    type: String,
    default: null,
  },
  modifiers: {
    type: Array,
    required: true,
  },
  modifierScope: {
    type: String,
    required: true,
  },
  modifiersMaxCount: {
    type: Number,
    default: 4,
  },
  tableShowSelect: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits([
  "deleteModifier",
  "modifierSaved",
  "resetModifiers",
  "searchModifierSelected",
])

const store = useGlobalStore()

const modifierEditKey = ref(null)
const modifierToEdit = ref(null)
const newModifier = ref(true)
const showModifierForm = ref(false)

const modifierNoun = computed(() => {
  let singular = "ability"
  let plural = "abilities"
  if (props.modifierScope === "global") {
    singular = "modifier"
    plural = "modifiers"
  }

  function sentenceCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return {
    singular: {
      lower: singular,
      sentence: sentenceCase(singular),
    },
    plural: {
      lower: plural,
      sentence: sentenceCase(plural),
    },
  }
})

const modifiersCount = computed(() => {
  return props.modifiers.length
})

const modifiersMaxCountReached = computed(() => {
  return modifiersCount.value >= props.modifiersMaxCount
})

const newModifierBtnDataCy = computed(() => {
  return `btn-new-${props.modifierScope}-${modifierNoun.value.singular.lower}`
})

const selectedModifiers = computed({
  get() {
    if (props.modifierScope === "global") {
      return store.modifiersGlobalSelected
    }
    return []
  },
  set(selectedModifierIds) {
    if (props.modifierScope === "global") {
      store.modifiersGlobalSelected = selectedModifierIds
    }
  },
})

const tableHeaders = computed(() => {
  const headers = [
    {
      title: "Description",
      key: "description",
      sortable: false,
    },
    {
      title: "",
      key: "actions",
      sortable: false,
    },
  ]

  if (props.modifierScope === "profile") {
    headers.splice(1, 0, {
      title: "Shared",
      key: "sharedWithAllUnits",
      sortable: false,
    })
  }

  for (const modifier of props.modifiers) {
    if (modifier?.aliases?.length && modifier?.aliasActive) {
      headers.splice(1, 0, {
        title: "Alias",
        key: "aliases",
        sortable: false,
      })
      break
    }
  }

  return headers
})

function newModifierHandler() {
  newModifier.value = true
  showModifierForm.value = true
}

function deleteModifierHandler(modifier) {
  emit("deleteModifier", modifier)
}

function editModifierHandler(item) {
  modifierEditKey.value = item.id
  modifierToEdit.value = item
  newModifier.value = false
  showModifierForm.value = true
}

function resetModifiersHandler() {
  emit("resetModifiers")
  showModifierForm.value = false
}

function modifierCancelledHandler() {
  showModifierForm.value = false
}

function modifierSavedHandler(modifier) {
  emit("modifierSaved", modifier)
  showModifierForm.value = false
}

function rowProps(data) {
  return { class: sanitiseClassName(data.item.description) }
}

function searchModifierSelectedHandler(modifier) {
  emit("searchModifierSelected", modifier)
}
</script>

<template>
  <div class="modifier-builder">
    <div class="heading-action-wrapper">
      <h4 v-if="heading" class="heading">{{ heading }}</h4>

      <div class="actions">
        <SearchModifiers
          :modifier-scope="modifierScope"
          :disabled="modifiersMaxCountReached"
          class="btn-add-preset"
          @modifier-selected="searchModifierSelectedHandler($event)"
        />
        <UcBtn
          :data-cy="newModifierBtnDataCy"
          :disabled="modifiersMaxCountReached"
          size="small"
          @click="newModifierHandler"
          >Create {{ modifierNoun.singular.lower }}
        </UcBtn>
        <UcBtn
          v-if="modifiers.length"
          :data-cy="`remove-all-modifiers--${modifierScope}`"
          size="small"
          @click="resetModifiersHandler"
        >
          Remove all</UcBtn
        >
      </div>
    </div>

    <UcAlert v-if="modifiersMaxCountReached" class="mt-4">
      {{ modifierNoun.plural.sentence }} are capped at a maximum of
      {{ modifiersMaxCount }}. You'll need to remove one before you can add any
      more.
    </UcAlert>

    <v-data-table
      v-if="modifiers.length"
      v-model="selectedModifiers"
      :headers="tableHeaders"
      :items="modifiers"
      class="mt-4"
      data-cy="table-modifiers"
      :show-select="tableShowSelect"
      :row-props="rowProps"
    >
      <template #item.sharedWithAllUnits="{ item }">
        <Icon v-if="item.sharedWithAllUnits" icon="mdi:check" height="18" />
        <template v-else>—</template>
      </template>

      <template #item.aliases="{ item }">
        <div class="aliases">
          {{ aliasesFormatted(item) }}
        </div>
      </template>

      <template #item.actions="{ item }">
        <div class="row-actions">
          <Icon
            icon="mdi:pencil"
            title="Edit"
            height="20"
            @click.stop="editModifierHandler(item)"
          />
          <Icon
            icon="mdi:delete"
            title="Delete"
            height="20"
            @click="deleteModifierHandler(item)"
          />
        </div>
      </template>

      <!-- Hides pagination -->
      <template #bottom />
    </v-data-table>

    <DialogCommon v-if="showModifierForm" v-model="showModifierForm">
      <EditModifier
        :key="modifierEditKey"
        :modifier-scope="modifierScope"
        :new-modifier="newModifier"
        :incoming-modifier="modifierToEdit"
        @modifier-cancelled="modifierCancelledHandler()"
        @modifier-saved="modifierSavedHandler($event)"
      />
    </DialogCommon>
  </div>
</template>

<style lang="scss" scoped>
.modifier-builder {
  container-type: inline-size;
}

.heading {
  margin-bottom: 1rem;
}

@container (min-width: 36rem) {
  .heading-action-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .heading {
    margin-bottom: 0;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.btn-add-preset {
  display: inline-flex;
}
.aliases {
  text-transform: uppercase;
}
</style>
