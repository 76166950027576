import { computed, ref } from "vue"
import { cloneDeep, merge } from "es-toolkit"

export function useProfile() {
  const profile = ref({
    abilityIdNext: 1,
    abilitiesSelected: [],
    computed: {
      keywords: [],
      leaders: [],
      modelCount: null,
      roles: [],
      toughness: null,
      wounds: null,
    },
    modelTypeIdNext: 1,
    name: null,
    orderSlain: {
      auto: true,
      order: [],
    },
    unitIdNext: 1,
    units: [],
    weaponIdNext: 1,
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  })

  const unit = ref({
    abilities: [],
    id: null,
    leader: false,
    modelTypes: [],
    name: null,
    points: null,
  })

  const modelType = ref({
    id: null,
    ignore: null,
    invuln: null,
    keywords: [],
    modelCount: null,
    name: null,
    save: null,
    toughness: null,
    weapons: [],
    wounds: null,
  })

  function mergeDefaultsInToProfile(incomingProfile) {
    // Merges incoming profile with the default profile,
    // adding in any missing properties and setting their values.
    // Mutates the incoming profile object.
    return merge(cloneDeep(profile.value), incomingProfile)
  }

  const minimalProfile = computed(() => {
    // Returns a cloned, minimal, default profile
    // comprising a single unit with a single modelType.
    const returnProfile = cloneDeep(profile.value)
    returnProfile.units.push(cloneDeep(unit.value))
    returnProfile.units[0].modelTypes.push(cloneDeep(modelType.value))
    return returnProfile
  })

  function ensureCompleteProfile(incomingProfile) {
    // Ensures that the incoming profile has all the required properties.
    const completeProfile = merge(minimalProfile.value, incomingProfile)
    // Ensure each unit is complete.
    completeProfile.units.forEach((currentUnit) => {
      const currentUnitMerged = merge(cloneDeep(unit.value), currentUnit)
      // Ensure each modelType is complete.
      currentUnitMerged.modelTypes.forEach((currentModelType) => {
        merge(cloneDeep(modelType.value), currentModelType)
      })
    })
    return completeProfile
  }

  function transformAdHocProfile(adHocProfile, profileType) {
    // Returns a full profile by cloning and transforming
    // an ad hoc profile with various defaults merged in.

    const returnProfile = minimalProfile.value
    returnProfile.orderSlain.order = [
      {
        modelTypeId: returnProfile.units[0].modelTypes[0].id,
        modelTypeName: null,
        unitId: returnProfile.units[0].id,
      },
    ]
    returnProfile.units[0].points = adHocProfile.points
    returnProfile.computed.points = adHocProfile.points

    if (profileType === "attacker") {
      returnProfile.computed.roles.push("Attacker: Ranged")
      returnProfile.units[0].modelTypes[0].weapons = adHocProfile.weapons
    }

    if (profileType === "defender") {
      returnProfile.computed.keywords = adHocProfile.keywords
      returnProfile.computed.modelCount = adHocProfile.modelCount
      returnProfile.computed.roles.push("Defender")
      returnProfile.computed.toughness = adHocProfile.toughness
      returnProfile.computed.wounds = adHocProfile.wounds
      merge(returnProfile.units[0].modelTypes[0], adHocProfile)
    }

    return returnProfile
  }

  return {
    ensureCompleteProfile,
    mergeDefaultsInToProfile,
    modelType,
    profile,
    transformAdHocProfile,
    unit,
  }
}
