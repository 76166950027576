import { cloneDeep } from "es-toolkit"

function timestamp() {
  // Timestamp in seconds
  // Credit: https://stackoverflow.com/a/221297/464066
  return Math.floor(Date.now() / 1000)
}

export default function cleanProfile(incomingProfile, options = {}) {
  // console.log("incomingProfile", incomingProfile)

  // Assign constants to `options` properties and set defaults.
  // Credit: https://dmitripavlutin.com/javascript-object-destructuring/
  const {
    // Whether to directly mutate the incoming modifier or not.
    mutate = false,
  } = options

  const profile = mutate ? incomingProfile : cloneDeep(incomingProfile)

  // Delete root level properties with null values.
  Object.keys(profile).forEach((key) => {
    if (profile[key] === null) {
      delete profile[key]
    }
  })

  // Delete empty abilities.
  if (typeof profile.abilities !== "undefined") {
    if (!profile.abilities.length) {
      delete profile.abilities
    }
  }

  // Delete empty abilitiesSelected.
  if (typeof profile.abilitiesSelected !== "undefined") {
    if (!profile.abilitiesSelected.length) {
      delete profile.abilitiesSelected
    }
  }

  // Delete empty keywords.
  if (typeof profile.keywords !== "undefined") {
    if (!profile.keywords.length) {
      delete profile.keywords
    }
  }

  // Delete empty weapons.
  if (typeof profile.weapons !== "undefined") {
    if (!profile.weapons.length) {
      delete profile.weapons
    }
  }

  // Delete empty weaponsSelected.
  if (typeof profile.weaponsSelected !== "undefined") {
    if (!profile.weaponsSelected.length) {
      delete profile.weaponsSelected
    }
  }

  profile.updated = timestamp()

  // console.log("profile", profile)

  return profile
}
