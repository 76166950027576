<script setup>
import { cloneDeep } from "es-toolkit"
import { useGlobalStore } from "@/stores/global"
import { aliasesFormatted, sanitiseClassName } from "~/utils/utils"
import { computed } from "vue"
import { Icon } from "@iconify/vue"

const store = useGlobalStore()

// Model types table

const modelTypesTableData = computed(() => {
  // A flat list of all defender model types, matching the order of orderSlain.
  const returnArray = []

  store.currentDefender.orderSlain.order.forEach((orderSlainModelType) => {
    store.currentDefender.units.find((unit) => {
      unit.modelTypes.find((modelType) => {
        if (modelType.id === orderSlainModelType.modelTypeId) {
          const modelTypeClone = cloneDeep(modelType)
          if (modelTypeClone.save) {
            modelTypeClone.save = `${modelTypeClone.save}+`
          }
          if (modelTypeClone.invuln) {
            modelTypeClone.invuln = `${modelTypeClone.invuln}+`
          }
          if (modelTypeClone.ignore) {
            modelTypeClone.ignore = `${modelTypeClone.ignore}+`
          }
          modelTypeClone.leader = unit.leader
          returnArray.push(modelTypeClone)
        }
      })
    })
  })

  return returnArray
})

const multipleModelTypes = computed(() => {
  return modelTypesTableData.value.length > 1
})

const modelTypesTableHeaders = computed(() => {
  const allHeaders = [
    { title: "Name", key: "name", sortable: false },
    { title: "Model count", key: "modelCount", sortable: false },
    { title: "T", key: "toughness", sortable: false },
    { title: "W", key: "wounds", sortable: false },
    { title: "Sv", key: "save", sortable: false },
    { title: "Invuln", key: "invuln", sortable: false },
    { title: "FNP", key: "ignore", sortable: false },
    { title: "Keywords", key: "keywords", sortable: false },
  ]
  // Filter out values of null & empty arrays.
  // i.e. Filter out "ignore", "invlun", "keywords", "save" if not used.
  return allHeaders.filter((header) => {
    for (const modelType of modelTypesTableData.value) {
      if (typeof modelType[header.key] !== "undefined") {
        if (modelType[header.key] !== null) {
          // noinspection RedundantIfStatementJS
          if (modelType[header.key].length < 1) {
            return false
          }
          return true
        }
      }
    }
  })
})

// Abilities table

const abilitiesTableData = computed(() => {
  return store.currentDefenderAbilitiesFiltered
})

const abilitiesTableHeaders = computed(() => {
  const headers = []

  if (store.currentDefender.units.length > 1) {
    headers.push({
      title: "Unit",
      key: "unit",
      sortable: false,
    })
  }

  headers.push({
    title: "Profile ability",
    key: "description",
    sortable: false,
  })

  // Add "Alias" header if required.
  let showAliasHeader = false
  abilitiesTableData.value.forEach((ability) => {
    if (ability.aliases.length && ability.aliasActive) {
      // At least one of this profile's abilities has an alias.
      showAliasHeader = true
    }
  })
  if (showAliasHeader) {
    headers.push({ title: "Alias", key: "aliases", sortable: false })
  }

  if (store.currentDefender.units.length > 1) {
    headers.push({
      title: "Shared",
      key: "sharedWithAllUnits",
      sortable: false,
    })
  }

  return headers
})

const selectedAbilities = computed({
  get() {
    return store.currentDefenderAbilitiesSelectedFiltered.map(
      (ability) => ability.id
    )
  },
  set(abilitiesData) {
    store.setCurrentDefenderAbilitiesSelected({
      profileId: store.currentDefender.id,
      abilitiesData,
    })
  },
})

function rowProps(data) {
  return { class: sanitiseClassName(data.item.description) }
}
</script>

<template>
  <div class="component-wrapper">
    <div v-if="multipleModelTypes" class="font-weight-bold mt-4 mb-2">
      Model types
    </div>

    <v-data-table
      :headers="modelTypesTableHeaders"
      :items="modelTypesTableData"
      data-cy="table-defender-stats"
    >
      <template #item.name="{ item }">
        <div class="name">
          <span>{{ item.name }}</span>
          <Icon
            v-if="item.leader"
            icon="mdi:medal"
            height="18"
            color="silver"
            class="icon ml-1"
          />
        </div>
      </template>
      <template #item.keywords="{ item }">
        <v-chip
          v-for="keyword in item.keywords"
          :key="keyword"
          size="small"
          class="ma-1"
        >
          {{ keyword }}
        </v-chip>
      </template>
      <!-- Hides pagination -->
      <template #bottom />
    </v-data-table>

    <template v-if="abilitiesTableData.length">
      <div class="font-weight-bold mt-4 mb-2">Relevant unit abilities</div>

      <v-data-table
        v-model="selectedAbilities"
        show-select
        :disabled="store.readOnlyProfiles"
        :headers="abilitiesTableHeaders"
        :items="abilitiesTableData"
        item-key="id"
        data-cy="table-defender-abilities"
        :row-props="rowProps"
      >
        <template #item.unit="{ item }">
          {{ item.computed.sourceUnit.name }}
        </template>
        <template #item.aliases="{ item }">
          <div class="aliases">
            {{ aliasesFormatted(item) }}
          </div>
        </template>
        <template #item.sharedWithAllUnits="{ item }">
          <Icon v-if="item.sharedWithAllUnits" icon="mdi:check" height="18" />
          <template v-else>—</template>
        </template>
        <!-- Hides pagination -->
        <template #bottom />
      </v-data-table>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.aliases {
  text-transform: uppercase;
}
.name {
  display: flex;
  align-items: center;
}
</style>
