<script setup>
import BtnAddProfile from "@/components/Common/BtnAddProfile"
import DefenderStats from "@/components/OneVsOne/ProfileStats/DefenderStats"
import { useGlobalStore } from "@/stores/global"
import EntitySelector from "@/components/Common/EntitySelector.vue"
import UcBtn from "@/components/UC/UcBtn.vue"
import { computed, defineAsyncComponent, ref } from "vue"
import UcAlert from "@/components/UC/UcAlert.vue"
import { useEditProfileStore } from "@/stores/editProfile"

const DialogCommon = defineAsyncComponent(
  () => import("@/components/Common/DialogCommon.vue")
)
const EditProfile = defineAsyncComponent(
  () => import("@/components/EditProfile/Profile/EditProfile.vue")
)

const storeEditProfile = useEditProfileStore()
const store = useGlobalStore()

const showDialogAddProfile = ref(false)
const showDialogEditProfile = ref(false)

const currentDefender = computed({
  get() {
    return store.currentDefender
  },
  set(obj) {
    store.setCurrentDefender(obj)
  },
})

const processedDefenders = computed(() => {
  return store.profilesProcessedDefenders ?? []
})

function setCurrentProfileId() {
  if (typeof currentDefender.value.id !== "undefined") {
    return currentDefender.value.id
  }
}
function editProfile() {
  storeEditProfile.limitProfileFormAccess = true
  storeEditProfile.intendedRole = "Defender"
  showDialogEditProfile.value = true
}
</script>

<template>
  <div>
    <UcAlert v-if="!processedDefenders.length" class="mb-4">
      You don't have any profiles that meet the requirements for being a
      defender. Update an existing profile or create a new one.
    </UcAlert>

    <div class="buttons">
      <EntitySelector
        v-if="processedDefenders.length"
        v-model="currentDefender"
        label="Saved profiles"
        :items="processedDefenders"
        item-text="name"
        :disabled="store.readOnlyProfiles"
        filter-input-label="Select defender profile"
        data-cy="input-select-defender"
      />
      <BtnAddProfile
        role="Defender"
        size="small"
        data-cy="btn-add-defender"
        @click="showDialogAddProfile = true"
      />
      <UcBtn
        v-if="!processedDefenders.length"
        to="/manage-profiles"
        size="small"
      >
        Manage profiles
      </UcBtn>
      <UcBtn
        v-if="currentDefender.name"
        data-cy="btn-edit-defender"
        :disabled="store.readOnlyProfiles"
        size="small"
        @click="editProfile()"
      >
        Edit profile
      </UcBtn>
    </div>

    <DefenderStats
      v-if="currentDefender.name"
      :key="Number(`${currentDefender.id}${currentDefender.updated}`)"
    />

    <DialogCommon v-if="showDialogAddProfile" v-model="showDialogAddProfile">
      <EditProfile
        :key="Date.now()"
        set-as-current="Defender"
        @close="showDialogAddProfile = false"
      />
    </DialogCommon>

    <DialogCommon v-if="showDialogEditProfile" v-model="showDialogEditProfile">
      <EditProfile
        :key="Date.now()"
        :profile-id="setCurrentProfileId()"
        set-as-current="Defender"
        @close="showDialogEditProfile = false"
      />
    </DialogCommon>
  </div>
</template>

<style lang="scss" scoped>
.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
