<script setup>
import InputInt from "@/components/EditProfile/Inputs/shared/InputInt"
import useVuelidate from "@vuelidate/core"
import { between, integer } from "@vuelidate/validators"
import Weapons from "@/components/OneVsOne/ProfileUi/AdHoc/Weapons"
import FormRequiredFieldsNote from "@/components/Common/FormRequiredFieldsNote"
import { useGlobalStore } from "@/stores/global"
import { cloneDeep } from "es-toolkit"
import UcBtn from "@/components/UC/UcBtn.vue"
import { ensureNumberDiceOrNull } from "~/utils/utils"
import { computed, nextTick, onMounted, ref, watch } from "vue"
import { useVuelidateErrorsModelType } from "@/composables/useVuelidateErrorsModelType.js"
import { Icon } from "@iconify/vue"

const store = useGlobalStore()

const formDirty = ref(true)
const loaded = ref(false)
const modelType = ref({
  points: null,
  weapons: [],
})
const weaponsKey = ref(null)

const validations = computed(() => ({
  modelType: {
    points: {
      between: between(1, 4000),
      integer,
    },
  },
}))

const v$ = useVuelidate(validations, { modelType })
const { errorsPoints } = useVuelidateErrorsModelType(v$)

function confirmProfile() {
  v$.value.$validate()
  if (!v$.value.$invalid) {
    // Make a clone to avoid mutating profile.value and triggering the watcher.
    const profileProcessed = cloneDeep(modelType.value)
    profileProcessed.weapons.forEach((weapon) => {
      // Cut down version of processWeapon() for ad hoc profiles.
      // Correct any back to front dice notation.
      weapon.attacks = ensureNumberDiceOrNull(weapon.attacks)
      weapon.damage = ensureNumberDiceOrNull(weapon.damage)
    })
    // console.log("profileProcessed", profileProcessed)
    // Set points to null if it's been set to "".
    // All other attacker inputs are required.
    if (profileProcessed.points === "") {
      profileProcessed.points = null
    }
    store.confirmAdHocProfile({
      profile: {
        ...profileProcessed,
        updated: Date.now(),
      },
      profileType: "attacker",
    })
    store.currentAttackerAdHoc.confirmed = true
    formDirty.value = false
  } else {
    store.currentAttackerAdHoc.confirmed = false
  }
}

function resetProfile() {
  modelType.value = {
    points: null,
    weapons: [],
  }
  store.resetCurrentAttackerAdHoc()
  weaponsKey.value = Date.now()
  v$.value.$reset()
}

watch(
  () => modelType,
  () => {
    if (loaded.value) {
      formDirty.value = true
      store.currentAttackerAdHoc.confirmed = false
    }
  },
  { deep: true }
)

onMounted(async () => {
  if (store.currentAttackerAdHoc.profile?.weapons?.length) {
    modelType.value = store.currentAttackerAdHoc.profile
  }
  await nextTick()
  // TODO: Bit nasty but it'll do for now.
  // This delay helps prevent the attacker profile from being set back to unconfirmed on UC init.
  setTimeout(() => {
    loaded.value = true
  }, 500)
})
</script>

<template>
  <v-form class="ad-hoc-attacker-inputs" @submit.prevent="confirmProfile">
    <div class="form-inputs">
      <div class="attacker-inputs">
        <InputInt
          v-model="modelType.points"
          :error-messages="errorsPoints()"
          data-cy="input-profile-points-attacker"
          hint="Points cost for the whole unit inc. wargear"
          label="Points"
          max="4000"
          min="0"
          @blur="v$.modelType.points.$touch()"
          @input="v$.modelType.points.$touch()"
        />
      </div>
      <Weapons :key="weaponsKey" v-model="modelType.weapons" />
    </div>
    <div class="form-footer">
      <FormRequiredFieldsNote class="notes" />
      <div class="buttons">
        <UcBtn
          v-if="!store.currentAttackerAdHoc.confirmed"
          type="submit"
          data-cy="btn-confirm-profile-attacker"
          variant="outlined"
          color="primary"
          >Confirm</UcBtn
        >
        <UcBtn
          v-else
          disabled
          variant="outlined"
          data-cy="btn-confirm-profile-attacker"
        >
          <span>Confirmed</span>
          <Icon icon="mdi:check" :inline="true" class="ml-2" />
        </UcBtn>
        <UcBtn class="ml-2" @click="resetProfile">Reset</UcBtn>
      </div>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.attacker-inputs {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.form-footer {
  .buttons {
    margin-top: 16px;
  }
}

@media (min-width: 30rem) {
  .form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .buttons {
      margin-top: 0;
    }
  }
}

@media (min-width: 40rem) {
  .attacker-inputs {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 60rem) {
  .attacker-inputs {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 75rem) {
  .attacker-inputs {
    grid-template-columns: repeat(5, 1fr);
  }
}
</style>
