<script setup>
import WeaponResultsTabs from "@/components/OneVsOne/Results/WeaponResults/WeaponResultsTabs"
import { useGlobalStore } from "@/stores/global"
import { computed, defineAsyncComponent } from "vue"

const TabsDamage = defineAsyncComponent(
  () =>
    import("@/components/OneVsOne/Results/OverallResults/Damage/TabsDamage.vue")
)
const TabsModelsSlain = defineAsyncComponent(
  () =>
    import(
      "@/components/OneVsOne/Results/OverallResults/ModelsSlain/TabsModelsSlain.vue"
    )
)

const store = useGlobalStore()

const showDamageDealt = computed(() => {
  return (
    store.getDefenderForSim.computed.wounds > 1 &&
    store.getDefenderForSim.computed.modelCount === 1
  )
})
</script>

<template>
  <div>
    <div v-if="store.simResultsReady" data-cy="sim-results">
      <div class="bg-grey-darken-4 mb-4">
        <TabsDamage v-if="showDamageDealt" />
        <TabsModelsSlain v-else />
      </div>

      <WeaponResultsTabs />
    </div>
  </div>
</template>
