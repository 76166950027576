<script setup>
import { useGlobalStore } from "@/stores/global"
import { computed } from "vue"
import { Icon } from "@iconify/vue"
import UcCheckbox from "@/components/UC/UcCheckbox.vue"

const store = useGlobalStore()

const panelLabel = computed(() => {
  const attackTypeKey = store.attackContext.attackType.toLowerCase()
  const active = store.attackContextCount.active[attackTypeKey]
  const total = store.attackContextCount.total[attackTypeKey]
  return total ? `Attack context: ${active}/${total}` : "Attack context"
})
</script>

<template>
  <v-expansion-panel class="expansion-panel-attack-context">
    <v-expansion-panel-title data-cy="btn-toggle-exp-panel-attack-context">
      <div class="header-inner">
        <div class="header-icon">
          <Icon icon="mdi:axis-arrow" height="22" />
        </div>
        <div class="header-label">
          {{ panelLabel }}
        </div>
      </div>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <div class="attack-context-options">
        <template v-if="store.attackContext.attackType === 'Melee'">
          <UcCheckbox
            v-model="store.attackContext.melee.attackerCharged"
            label="Attacker charged"
            data-cy="input-attack-context-attacker-charged"
            hide-details
          />
        </template>

        <template v-if="store.attackContext.attackType === 'Ranged'">
          <UcCheckbox
            v-model="store.attackContext.ranged.remainedStationary"
            label="Attacker remained stationary"
            data-cy="input-attack-context-remained-stationary"
            hide-details
          />
          <UcCheckbox
            v-model="store.attackContext.ranged.defenderInCover"
            label="Defender in cover"
            data-cy="input-attack-context-defender-cover"
            hide-details
          />
          <UcCheckbox
            v-model="store.attackContext.ranged.withinHalfRange"
            label="Within half range"
            data-cy="input-attack-context-within-half-range"
            hide-details
          />
        </template>
      </div>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<style lang="scss" scoped>
.header-inner {
  display: flex;
  align-items: center;
}
.header-icon {
  flex: 0 1 auto;
  margin-right: 24px;
}
.header-label {
  flex: 1 1 auto;
}
.attack-context-options {
  .v-checkbox {
    display: inline-block;
    margin-right: 32px;
  }
  .v-input--checkbox {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
