export function useVuelidateErrorsModelType(v$) {
  const errorsIgnore = () => {
    const errors = []
    if (!v$.value.modelType.ignore.$dirty) return errors
    v$.value.modelType.ignore.integer.$invalid &&
      errors.push("Must be a number")
    v$.value.modelType.ignore.between.$invalid &&
      errors.push("Must be between 2 and 6")
    return errors
  }

  const errorsInvuln = () => {
    const errors = []
    if (!v$.value.modelType.invuln.$dirty) return errors
    v$.value.modelType.invuln.integer.$invalid &&
      errors.push("Must be a number")
    v$.value.modelType.invuln.between.$invalid &&
      errors.push("Must be between 2 and 6")
    return errors
  }

  const errorsModelCount = () => {
    const errors = []
    if (!v$.value.modelType.modelCount.$dirty) return errors
    v$.value.modelType.modelCount.integer.$invalid &&
      errors.push("Must be a number")
    v$.value.modelType.modelCount.between.$invalid &&
      errors.push("Must be between 1 and 30")
    v$.value.modelType.modelCount.required.$invalid &&
      errors.push("Value required")
    return errors
  }

  const errorsName = () => {
    const errors = []
    if (!v$.value.modelType.name.$dirty) return errors
    v$.value.modelType.name.maxLength.$invalid &&
      errors.push("Maximum: 100 characters")
    v$.value.modelType.name.required.$invalid && errors.push("Value required")
    return errors
  }

  const errorsPoints = () => {
    const errors = []
    if (!v$.value.modelType.points.$dirty) return errors
    v$.value.modelType.points.between.$invalid &&
      errors.push("Must be between 1 and 4000")
    v$.value.modelType.points.integer.$invalid &&
      errors.push("Must be a number")
    return errors
  }

  const errorsSave = () => {
    const errors = []
    if (!v$.value.modelType.save.$dirty) return errors
    v$.value.modelType.save.integer.$invalid && errors.push("Must be a number")
    v$.value.modelType.save.between.$invalid &&
      errors.push("Must be between 2 and 8")
    v$.value.modelType.save.required.$invalid && errors.push("Value required")
    return errors
  }

  const errorsToughness = () => {
    const errors = []
    if (!v$.value.modelType.toughness.$dirty) return errors
    v$.value.modelType.toughness.integer.$invalid &&
      errors.push("Must be a number")
    v$.value.modelType.toughness.between.$invalid &&
      errors.push("Must be between 1 and 16")
    v$.value.modelType.toughness.required.$invalid &&
      errors.push("Value required")
    return errors
  }

  const errorsWounds = () => {
    const errors = []
    if (!v$.value.modelType.wounds.$dirty) return errors
    v$.value.modelType.wounds.integer.$invalid &&
      errors.push("Must be a number")
    v$.value.modelType.wounds.between.$invalid &&
      errors.push("Must be between 1 and 120")
    v$.value.modelType.wounds.required.$invalid && errors.push("Value required")
    return errors
  }

  return {
    errorsIgnore,
    errorsInvuln,
    errorsModelCount,
    errorsName,
    errorsPoints,
    errorsSave,
    errorsToughness,
    errorsWounds,
  }
}
