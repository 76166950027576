<script setup lang="ts">
import AttackerExpansionPanel from "@/components/OneVsOne/ExpansionPanels/Attacker"
import DefenderExpansionPanel from "@/components/OneVsOne/ExpansionPanels/Defender"
import Loading from "@/components/Common/Loading"
import { useGlobalStore } from "@/stores/global"
import { usePageCrunchSingleStore } from "@/stores/pageCrunchSingle"
import SimsExpansionPanel from "@/components/OneVsOne/ExpansionPanels/Simulations"
import { debounce } from "es-toolkit"
import { computed, defineAsyncComponent, onMounted, watch } from "vue"
import { configSims } from "~/utils/config.js"
import { useUserConfigSimsStore } from "@/stores/userConfigSims"

const AttackContextPanel = defineAsyncComponent(
  () => import("@/components/OneVsOne/ExpansionPanels/AttackContext.vue")
)
const GlobalModifiersPanel = defineAsyncComponent(
  () => import("@/components/OneVsOne/ExpansionPanels/GlobalModifiers.vue")
)
const NoResults = defineAsyncComponent(
  () => import("@/components/OneVsOne/Results/NoResults.vue")
)
const OneVsOneResults = defineAsyncComponent({
  loader: () => import("@/components/OneVsOne/Results/OneVsOneResults.vue"),
  loadingComponent: Loading,
})
const RunSim = defineAsyncComponent(() => import("@/components/Sim/RunSim.vue"))

const store = useGlobalStore()
const storeCrunchSingle = usePageCrunchSingleStore()
const storeUserConfigSims = useUserConfigSimsStore()

const runtimeConfig = useRuntimeConfig()

const stickyCol2 = computed(() => {
  return store.currentAttackerWeaponsSelectedFiltered.length < 4
})

function tryRunSim() {
  debounce(function () {
    // Credit for watch/debounce example: https://stackoverflow.com/a/45178679

    // If any profiles are ad hoc, make sure they're confirmed before auto-running a sim.
    if (!store.simReqsMet) {
      return
    }
    store.tryRunSim("auto")
  }, 100)()
}

watch(
  () => store.simResultsKey,
  () => {
    tryRunSim()
  }
)

onMounted(() => {
  // TODO: This is a lame workaround for a race condition.
  // This component could mount before an update sets state.updateInProgress true.
  if (!store.simResultsKeyCached) {
    if (
      store.appVersionLastSeen === "0.0.0" ||
      store.appVersionLastSeen === runtimeConfig.public.clientVersion
    ) {
      // Try automatically running a sim, but only if we're not about to do an update.
      store.tryRunSim("auto")
    }
  }

  // Reset the simulation if auto-run sims is disabled
  // or sim count is set to > config.maxAutoRunSims.
  // This forces the user to trigger the first sim and avoids stale data being shown,
  // which can lead to wonky results, confusion & underlying type errors.
  if (
    !storeUserConfigSims.autoRunSims ||
    storeUserConfigSims.totalSims > configSims.maxAutoRunSims
  ) {
    store.simulation = {
      simId: null,
      simResults: null,
    }
  }
})
</script>

<template>
  <div class="page-wrapper">
    <ClientOnly>
      <RunSim v-if="store.simReqsMet" />
    </ClientOnly>
    <v-progress-linear
      v-if="store.simulationRunning"
      absolute
      indeterminate
      color="primary"
      class="sim-progress-fixed"
    />
    <h1 class="d-sr-only">Crunch (MathHammer attack simulation)</h1>
    <div class="cols-wrapper">
      <v-expansion-panels
        v-model="storeCrunchSingle.panelsCrunchSingle"
        class="uc-col uc-col-1"
        :multiple="true"
      >
        <div class="column-inner">
          <ClientOnly>
            <AttackerExpansionPanel />
            <DefenderExpansionPanel />
            <AttackContextPanel />
            <GlobalModifiersPanel />
            <SimsExpansionPanel />
          </ClientOnly>
        </div>
      </v-expansion-panels>
      <div class="uc-col uc-col-2">
        <div :class="['column-inner', { sticky: stickyCol2 }]">
          <!-- Results are showing, possibly running another sim too. -->
          <ClientOnly>
            <OneVsOneResults
              v-if="
                !store.simulationRunning &&
                store.showSimResults &&
                store.simulation.simResults !== null
              "
              :key="store.simResultsReadyTime"
            />
            <!-- No results are showing & no sims are running. -->
            <NoResults
              v-if="!store.simulationRunning && !store.showSimResults"
            />
          </ClientOnly>
          <!-- Sim is running. -->
          <Loading v-if="store.simulationRunning" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sim-progress-fixed {
  z-index: 2000;
}
.column-inner {
  width: 100%;
}
.uc-col-2 {
  margin-top: 16px;
}

@media (min-width: 1905px) {
  .sim-progress-fixed {
    display: none;
  }
  .cols-wrapper {
    display: flex;
  }

  .uc-col {
    width: 50%;
  }

  .uc-col-1 {
    padding-right: 8px;
  }

  .uc-col-2 {
    margin-top: 0;
    padding-left: 8px;

    .sticky {
      @media (min-height: 1190px) {
        // This styling is not applied if weapons selected is >3.
        // That's the point at which we jump from 1 row of weapon results charts to 2 or more.
        position: sticky;
        top: 12px;
      }
    }
  }
}
</style>
