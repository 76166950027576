<script setup>
import InputInt from "@/components/EditProfile/Inputs/shared/InputInt"
import useVuelidate from "@vuelidate/core"
import { between, integer, required } from "@vuelidate/validators"
import InputKeywords from "@/components/EditProfile/Inputs/profile/InputKeywords"
import FormRequiredFieldsNote from "@/components/Common/FormRequiredFieldsNote"
import { useGlobalStore } from "@/stores/global"
import { cloneDeep } from "es-toolkit"
import UcBtn from "@/components/UC/UcBtn.vue"
import { computed, nextTick, onMounted, ref, watch } from "vue"
import { useVuelidateErrorsModelType } from "@/composables/useVuelidateErrorsModelType.js"
import { Icon } from "@iconify/vue"

const store = useGlobalStore()

const formDirty = ref(true)
const loaded = ref(false)
const modelType = ref({
  ignore: null,
  invuln: null,
  keywords: [],
  modelCount: null,
  points: null,
  save: null,
  toughness: null,
  wounds: null,
})

const validations = computed(() => ({
  modelType: {
    ignore: {
      integer,
      between: between(2, 6),
    },
    invuln: {
      integer,
      between: between(2, 6),
    },
    modelCount: {
      between: between(1, 30),
      integer,
      required,
    },
    points: {
      between: between(1, 4000),
      integer,
    },
    save: {
      between: between(2, 7),
      integer,
      required,
    },
    toughness: {
      between: between(1, 16),
      integer,
      required,
    },
    wounds: {
      between: between(1, 120),
      integer,
      required,
    },
  },
}))

const v$ = useVuelidate(validations, { modelType })
const {
  errorsIgnore,
  errorsInvuln,
  errorsModelCount,
  errorsPoints,
  errorsSave,
  errorsToughness,
  errorsWounds,
} = useVuelidateErrorsModelType(v$)

function confirmProfile() {
  v$.value.$validate()
  if (!v$.value.$invalid) {
    // Make a clone to avoid mutating profile.value and triggering the watcher.
    const profileProcessed = cloneDeep(modelType.value)
    // Find any values of "" and replace them with null.
    const profileEntries = Object.entries(profileProcessed)
    profileEntries.forEach((entry) => {
      if (entry[1] === "") {
        profileProcessed[entry[0]] = null
      }
    })
    store.confirmAdHocProfile({
      profile: {
        ...profileProcessed,
        updated: Date.now(),
      },
      profileType: "defender",
    })
    store.currentDefenderAdHoc.confirmed = true
    formDirty.value = false
  } else {
    store.currentDefenderAdHoc.confirmed = false
  }
}

function resetProfile() {
  modelType.value = {
    ignore: null,
    invuln: null,
    keywords: [],
    modelCount: null,
    points: null,
    save: null,
    toughness: null,
    wounds: null,
  }
  store.resetCurrentDefenderAdHoc()
  v$.value.$reset()
}

watch(
  () => modelType,
  () => {
    if (loaded.value) {
      formDirty.value = true
      store.currentDefenderAdHoc.confirmed = false
    }
  },
  { deep: true }
)

onMounted(async () => {
  if (typeof store.currentDefenderAdHoc.profile.modelCount !== "undefined") {
    modelType.value = store.currentDefenderAdHoc.profile
  }
  await nextTick()
  loaded.value = true
})
</script>

<template>
  <v-form class="ad-hoc-defender-inputs" @submit.prevent="confirmProfile">
    <div class="form-inputs">
      <div class="defender-inputs profile-template-standard">
        <InputInt
          v-model="modelType.modelCount"
          :error-messages="errorsModelCount()"
          data-cy="input-profile-model-count"
          max="30"
          min="1"
          label="Model count"
          required
          @blur="v$.modelType.modelCount.$touch()"
          @input="v$.modelType.modelCount.$touch()"
        />
        <InputInt
          v-model="modelType.toughness"
          label="Toughness"
          min="1"
          max="16"
          required
          :error-messages="errorsToughness()"
          data-cy="input-profile-toughness"
          @input="v$.modelType.toughness.$touch()"
          @blur="v$.modelType.toughness.$touch()"
        />

        <InputInt
          v-model="modelType.wounds"
          label="Wounds"
          min="1"
          max="120"
          required
          :error-messages="errorsWounds()"
          data-cy="input-profile-wounds"
          @input="v$.modelType.wounds.$touch()"
          @blur="v$.modelType.wounds.$touch()"
        />

        <InputInt
          v-model="modelType.save"
          label="Save"
          min="2"
          max="7"
          required
          :error-messages="errorsSave()"
          hint="Armour save"
          data-cy="input-profile-save"
          @input="v$.modelType.save.$touch()"
          @blur="v$.modelType.save.$touch()"
        />
        <InputInt
          v-model="modelType.invuln"
          label="Invuln"
          :error-messages="errorsInvuln()"
          hint="Invulnerable save"
          data-cy="input-profile-invuln"
          @input="v$.modelType.ignore.$touch()"
          @blur="v$.modelType.ignore.$touch()"
        />

        <InputInt
          v-model="modelType.ignore"
          label="Feel no pain"
          :error-messages="errorsIgnore()"
          hint="Roll to ignore wounds"
          data-cy="input-profile-ignore"
          @input="v$.modelType.ignore.$touch()"
          @blur="v$.modelType.ignore.$touch()"
        />

        <InputInt
          v-model="modelType.points"
          :error-messages="errorsPoints()"
          data-cy="input-profile-points-defender"
          hint="Points cost for the whole unit inc. wargear"
          label="Points"
          max="4000"
          min="0"
          @blur="v$.modelType.points.$touch()"
          @input="v$.modelType.points.$touch()"
        />

        <InputKeywords
          v-model="modelType.keywords"
          data-cy="input-profile-keywords"
          class="field-keywords"
        />
      </div>
    </div>
    <div class="form-footer">
      <FormRequiredFieldsNote class="notes" />
      <div class="buttons">
        <UcBtn
          v-if="!store.currentDefenderAdHoc.confirmed"
          type="submit"
          data-cy="btn-confirm-profile-defender"
          variant="outlined"
          color="primary"
          >Confirm</UcBtn
        >
        <UcBtn
          v-else
          disabled
          variant="outlined"
          data-cy="btn-confirm-profile-defender"
        >
          <span>Confirmed</span>
          <Icon icon="mdi:check" :inline="true" class="ml-2" />
        </UcBtn>
        <UcBtn class="ml-2" @click="resetProfile">Reset</UcBtn>
      </div>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.defender-inputs {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.field-profile-template {
  grid-column: 1 / -1;
}

.form-footer {
  .buttons {
    margin-top: 16px;
  }
}

@media (min-width: 28rem) {
  .field-profile-template {
    grid-column: auto;
  }
  .profile-template-standard {
    .field-keywords {
      grid-column: 1 / -1;
    }
  }
}

@media (min-width: 30rem) {
  .form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .buttons {
      margin-top: 0;
    }
  }
}

@media (min-width: 40rem) {
  .defender-inputs {
    grid-template-columns: repeat(3, 1fr);
  }
  .profile-template-standard {
    .field-keywords {
      grid-column: auto;
    }
  }
}

@media (min-width: 60rem) {
  .defender-inputs {
    grid-template-columns: repeat(4, 1fr);
  }
  .profile-template-standard {
    .field-keywords {
      grid-column-end: span 2;
    }
  }
}

@media (min-width: 75rem) {
  .defender-inputs {
    grid-template-columns: repeat(5, 1fr);
  }
  .profile-template-standard {
    .field-keywords {
      grid-column-end: span 2;
    }
  }
}
</style>
