export default function formatResult(input) {
  // Fixes decimal place to 1.
  // Avoids "n.0" decimal values.
  const inputFixed = Number(input).toFixed(1)
  const array = inputFixed.split(".")
  if (array[1] === "0") {
    return Number(array[0])
  }
  return inputFixed
}
