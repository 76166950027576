import cleanProfile from "~/utils/crud/cleanProfile"
import semverGt from "semver/functions/gt"
import { cloneDeep } from "es-toolkit"
import { configSims } from "~/utils/config"
import { createPinia, defineStore } from "pinia"
import { mergeDefaultsInToWeapon } from "~/utils/defaults/weapon"
import { profileAbilitiesProcessed } from "~/utils/store"
import { timestamp } from "~/utils/utils"
import { toRaw } from "vue"
import updateProfile from "~/utils/update/updateProfile"
import updateModifier from "~/utils/update/updateModifier"
import { useProfile } from "@/composables/useProfile.js"
import { usePageCrunchSingleStore } from "@/stores/pageCrunchSingle"
import { useUserConfigSimsStore } from "@/stores/userConfigSims"
import { createSentryPiniaPlugin } from "@sentry/vue"

const pinia = createPinia()
pinia.use(createSentryPiniaPlugin())

const { mergeDefaultsInToProfile, transformAdHocProfile } = useProfile()

export const useGlobalStore = defineStore("global", {
  state: () => ({
    alertExplainScopeGlobal: true,
    alertNewVersion: false,
    appNavDrawer: false,
    appVersionLastSeen: "0.0.0",
    attackContext: {
      attackType: "Ranged",
      melee: {
        attackerCharged: false,
      },
      ranged: {
        defenderInCover: false,
        remainedStationary: false,
        withinHalfRange: false,
      },
    },
    averageType: "median",
    currentAttacker: {},
    currentAttackerAdHoc: {
      confirmed: false,
      profile: {},
    },
    currentDefender: {},
    currentDefenderAdHoc: {
      confirmed: false,
      profile: {},
    },
    lastSeen: null,
    // This provides a reserved range of 1-999 for preset modifiers initially.
    modifierGlobalIdNext: 1000,
    modifiersGlobal: [],
    modifiersGlobalSelected: [],
    readOnlyProfiles: false,
    newUser: true,
    profileIdNext: 1,
    profiles: [],
    profilesUpdated: 0,
    profileUiAttacker: "profile",
    profileUiDefender: "profile",
    simError: null,
    simLastManualTriggerTime: null,
    simResultsKeyCached: null,
    simResultsReady: false,
    simResultsReadyTime: null,
    simTriggerLast: null,
    simulation: {
      simId: null,
      simResults: null,
    },
    simulationRunning: false,
    updateInProgress: false,
    versionToUpdateFrom: null,
  }),
  getters: {
    attackContextCount(state) {
      const active = {
        melee: 0,
        ranged: 0,
      }
      const total = {
        melee: 0,
        ranged: 0,
      }
      Object.keys(state.attackContext.melee).forEach((key) => {
        total.melee++
        if (state.attackContext.melee[key]) {
          active.melee++
        }
      })
      Object.keys(state.attackContext.ranged).forEach((key) => {
        total.ranged++
        if (state.attackContext.ranged[key]) {
          active.ranged++
        }
      })
      return {
        active,
        total,
      }
    },

    currentAttackerCompatibleWithAttackType(state) {
      if (typeof state.currentAttacker?.computed?.roles !== "undefined") {
        if (state.attackContext.attackType === "Melee") {
          return state.currentAttacker.computed.roles.includes(
            "Attacker: Melee"
          )
        }
        if (state.attackContext.attackType === "Ranged") {
          return state.currentAttacker.computed.roles.includes(
            "Attacker: Ranged"
          )
        }
      }
      return false
    },
    currentAttackerAbilitiesFlat(state) {
      // A flat list of all attacker unit abilities.
      if (typeof state.currentAttacker.units === "undefined") {
        return []
      }
      const returnArray = []
      state.currentAttacker?.units.forEach((unit) => {
        if (typeof unit.abilities === "undefined") {
          return
        }
        unit.abilities.forEach((ability) => {
          const abilityProcessed = cloneDeep(ability)
          abilityProcessed.computed = {
            sourceUnit: {
              id: unit.id,
              name: unit.name,
            },
          }
          returnArray.push(abilityProcessed)
        })
      })
      return returnArray
    },
    currentAttackerAbilitiesFiltered(state) {
      // A flat list of all attacker unit abilities, filtered by profile role and attack type.
      const abilitiesProcessed = profileAbilitiesProcessed(
        cloneDeep(this.currentAttackerAbilitiesFlat)
      )

      return abilitiesProcessed.filter((ability) => {
        if (ability?.profileRole === "Attacker") {
          // Ability profile role matches the role it's currently being used in.
          if (!ability.attackType) {
            // Ability doesn't specify an attack type.
            return true
          }
          if (ability.attackType === state.attackContext.attackType) {
            // Ability does specify an attack type, and we're currently in that attack type.
            return true
          }
        }
        return false
      })
    },
    currentAttackerAbilitiesSelected(state) {
      // The current attacker's selected profile abilities.
      if (
        typeof state.currentAttacker.abilitiesSelected === "undefined" ||
        this.currentAttackerAbilitiesFlat.length === 0
      ) {
        return []
      }

      if (state.currentAttacker.abilitiesSelected.length === 0) {
        return []
      }

      const abilitiesProcessed = profileAbilitiesProcessed(
        this.currentAttackerAbilitiesFlat
      )

      return abilitiesProcessed.filter((ability) => {
        let idMatch = false
        for (const selectedId of state.currentAttacker.abilitiesSelected) {
          if (ability.id === selectedId) {
            idMatch = true
            break
          }
        }
        return idMatch
      })
    },
    currentAttackerAbilitiesSelectedFiltered(state) {
      // The current attacker's selected profile abilities, filtered by profile role and attack type.
      if (!this.currentAttackerAbilitiesFiltered.length) {
        return []
      }

      const abilitiesProcessed = profileAbilitiesProcessed(
        cloneDeep(this.currentAttackerAbilitiesSelected)
      )

      return abilitiesProcessed.filter((ability) => {
        if (ability?.profileRole === "Attacker") {
          // Ability profile role matches the role it's currently being used in.
          if (!ability.attackType) {
            // Ability doesn't specify an attack type.
            return true
          }
          if (ability.attackType === state.attackContext.attackType) {
            // Ability does specify an attack type, and we're currently in that attack type.
            return true
          }
        }
        return false
      })
    },
    currentDefenderAbilitiesFlat(state) {
      // A flat list of all defender unit abilities.
      if (typeof state.currentDefender.units === "undefined") {
        return []
      }
      const returnArray = []
      state.currentDefender?.units.forEach((unit) => {
        if (typeof unit.abilities === "undefined") {
          return
        }
        unit.abilities.forEach((ability) => {
          const abilityProcessed = cloneDeep(ability)
          abilityProcessed.computed = {
            sourceUnit: {
              id: unit.id,
              name: unit.name,
            },
          }
          returnArray.push(abilityProcessed)
        })
      })
      return returnArray
    },
    currentDefenderAbilitiesFiltered(state) {
      // A flat list of all defender unit abilities, filtered by profile role and attack type.

      const abilitiesProcessed = profileAbilitiesProcessed(
        cloneDeep(this.currentDefenderAbilitiesFlat)
      )

      return abilitiesProcessed.filter((ability) => {
        if (ability?.profileRole === "Defender") {
          // Ability profile role matches the role it's currently being used in.
          if (!ability.attackType) {
            // Ability doesn't specify an attack type.
            return true
          }
          if (ability.attackType === state.attackContext.attackType) {
            // Ability does specify an attack type, and we're currently in that attack type.
            return true
          }
        }
        return false
      })
    },
    currentDefenderAbilitiesSelected(state) {
      // The current defender's selected profile abilities.
      if (
        typeof state.currentDefender.abilitiesSelected === "undefined" ||
        this.currentDefenderAbilitiesFlat.length === 0
      ) {
        return []
      }

      if (state.currentDefender.abilitiesSelected.length === 0) {
        return []
      }

      const abilitiesProcessed = profileAbilitiesProcessed(
        this.currentDefenderAbilitiesFlat
      )

      return abilitiesProcessed.filter((ability) => {
        let idMatch = false
        for (const selectedId of state.currentDefender.abilitiesSelected) {
          if (ability.id === selectedId) {
            idMatch = true
            break
          }
        }
        return idMatch
      })
    },
    currentDefenderAbilitiesSelectedFiltered(state) {
      // The current defender's profile abilities, filtered by profile role and attack type.
      if (!this.currentDefenderAbilitiesFiltered.length) {
        return []
      }

      const abilitiesProcessed = profileAbilitiesProcessed(
        cloneDeep(this.currentDefenderAbilitiesSelected)
      )

      return abilitiesProcessed.filter((ability) => {
        if (ability?.profileRole === "Defender") {
          // Ability profile role matches the role it's currently being used in.
          if (!ability.attackType) {
            // Ability doesn't specify an attack type.
            return true
          }
          if (ability.attackType === state.attackContext.attackType) {
            // Ability does specify an attack type, and we're currently in that attack type.
            return true
          }
        }
        return false
      })
    },
    currentAttackerWeaponsFlat(state) {
      // A flat list of all attacker weapons.
      if (typeof state.currentAttacker.units === "undefined") {
        return []
      }
      const returnArray = []
      state.currentAttacker.units.forEach((unit) => {
        unit.modelTypes.forEach((modelType) => {
          if (typeof modelType.weapons !== "undefined") {
            modelType.weapons.forEach((weapon) => {
              // Add computed data for convenient access during a simulation.
              weapon.computed = {
                modelTypeId: modelType.id,
                unitId: unit.id,
              }
              returnArray.push(weapon)
            })
          }
        })
      })
      return returnArray
    },
    currentAttackerWeaponsFiltered(state) {
      // A flat list of all attacker weapons filtered by type.
      return this.currentAttackerWeaponsFlat.filter((weapon) => {
        return weapon.type === state.attackContext.attackType
      })
    },
    currentAttackerWeaponsSelected(state) {
      // The current attacker's selected weapons.
      if (
        typeof state.currentAttacker.weaponsSelected === "undefined" ||
        this.currentAttackerWeaponsFlat.length === 0
      ) {
        return []
      }

      // TODO: Shouldn't have to do this here.
      if (typeof state.currentAttacker.weaponsSelected?.melee === "undefined") {
        state.currentAttacker.weaponsSelected.melee = []
      }
      if (
        typeof state.currentAttacker.weaponsSelected?.ranged === "undefined"
      ) {
        state.currentAttacker.weaponsSelected.ranged = []
      }

      if (
        state.currentAttacker.weaponsSelected.melee.length === 0 &&
        state.currentAttacker.weaponsSelected.ranged.length === 0
      ) {
        return []
      }

      return cloneDeep(this.currentAttackerWeaponsFlat).filter((weapon) => {
        let idMatch = false
        for (const selectedIdMelee of state.currentAttacker.weaponsSelected
          .melee) {
          if (weapon.id === selectedIdMelee) {
            idMatch = true
            break
          }
        }
        for (const selectedIdRanged of state.currentAttacker.weaponsSelected
          .ranged) {
          if (weapon.id === selectedIdRanged) {
            idMatch = true
            break
          }
        }
        return idMatch
      })
    },
    currentAttackerWeaponsSelectedFiltered(state) {
      // The current attacker's **selected** weapons, filtered by melee or ranged compatibility.
      if (state.attackContext.attackType === "Melee") {
        return this.currentAttackerWeaponsSelected.filter(
          (weapon) => weapon.type === "Melee"
        )
      }
      if (state.attackContext.attackType === "Ranged") {
        return this.currentAttackerWeaponsSelected.filter(
          (weapon) => weapon.type !== "Melee"
        )
      }
      return []
    },

    // These getters handle the difference between ad hoc & profile input.
    getAttackerForSim(state) {
      if (state.profileUiAttacker === "adhoc") {
        // Return full profile using ad hoc profile as a base.
        return transformAdHocProfile(
          cloneDeep(state.currentAttackerAdHoc.profile),
          "attacker"
        )
      }
      return state.currentAttacker
    },
    getAttackContextForSim(state) {
      if (state.profileUiAttacker === "adhoc") {
        // Override attack context as though it is ranged.
        return {
          attackType: "Ranged",
          melee: {},
          ranged: {
            ...state.attackContext.ranged,
          },
        }
      }
      return this.attackContext
    },
    getWeaponsForSim(state) {
      if (state.profileUiAttacker === "adhoc") {
        if (typeof state.currentAttackerAdHoc.profile.weapons !== "undefined") {
          // Each weapon needs to be merged with the default weapon profile.
          return state.currentAttackerAdHoc.profile.weapons.map((weapon) => {
            const weaponClone = cloneDeep(weapon)
            weaponClone.countPerUnit = 1
            weaponClone.type = "Ranged"
            return mergeDefaultsInToWeapon(weaponClone)
          })
        }
        return []
      }
      return this.currentAttackerWeaponsSelectedFiltered
    },
    getAttackerAbilitiesForSim(state) {
      if (state.profileUiAttacker === "adhoc") {
        return []
      }
      return this.currentAttackerAbilitiesSelectedFiltered
    },
    getDefenderForSim(state) {
      if (state.profileUiDefender === "adhoc") {
        // Return full profile using ad hoc profile as a base.
        return transformAdHocProfile(
          cloneDeep(state.currentDefenderAdHoc.profile),
          "defender"
        )
      }
      return state.currentDefender
    },
    getDefenderAbilitiesForSim(state) {
      if (state.profileUiDefender === "adhoc") {
        return []
      }
      return this.currentDefenderAbilitiesSelectedFiltered
    },

    globalModifiersCount(state) {
      return {
        active: state.modifiersGlobalSelected.length,
        total: state.modifiersGlobal.length,
      }
    },
    globalModifiersFilteredBySelected(state) {
      const returnArray = []
      state.modifiersGlobalSelected.forEach((selectedId) => {
        state.modifiersGlobal.forEach((modifier) => {
          if (modifier.id === selectedId) {
            returnArray.push(toRaw(modifier))
          }
        })
      })
      return returnArray
    },

    profilesCount(state) {
      return state.profiles.length
    },
    profilesProcessedAttackers(state) {
      let processedAttackers = []
      if (state.profiles.length) {
        if (state.attackContext.attackType === "Ranged") {
          processedAttackers = this.profilesProcessedAttackersRanged
        }
        if (this.attackContext.attackType === "Melee") {
          processedAttackers = this.profilesProcessedAttackersMelee
        }
      }
      return processedAttackers
    },
    profilesProcessedAttackersMelee(state) {
      let processedAttackers = []
      if (state.profiles.length) {
        // Filter array to only include eligible melee attackers.
        processedAttackers = state.profiles.filter((profile) => {
          if (profile.computed?.roles.includes("Attacker: Melee")) {
            return true
          }
        })
        // Sort array by `updated` descending.
        processedAttackers.sort((a, b) => {
          return b.updated - a.updated
        })
      }
      return processedAttackers
    },
    profilesProcessedAttackersRanged(state) {
      let processedAttackers = []
      if (state.profiles.length) {
        // Filter array to only include eligible ranged attackers.
        processedAttackers = state.profiles.filter((profile) => {
          if (profile.computed?.roles.includes("Attacker: Ranged")) {
            return true
          }
        })
        // Sort array by `updated` descending.
        processedAttackers.sort((a, b) => {
          return b.updated - a.updated
        })
      }
      return processedAttackers
    },
    profilesProcessedDefenders(state) {
      let processedDefenders = []
      if (state.profiles.length) {
        // Filter array to only include eligible defenders.
        processedDefenders = state.profiles.filter((profile) => {
          if (profile.computed?.roles.includes("Defender")) {
            return true
          }
        })
        // Sort array by `updated` descending.
        processedDefenders.sort((a, b) => {
          return b.updated - a.updated
        })
      }
      return processedDefenders
    },

    showDamageNotIgnoredGetter() {
      for (const unit of this.getDefenderForSim.units) {
        for (const modelType of unit.modelTypes) {
          if (
            typeof modelType.ignore !== "undefined" &&
            modelType?.ignore !== null
          ) {
            return true
          }
        }
      }

      for (const defenderAbility of this
        .currentDefenderAbilitiesSelectedFiltered) {
        if (defenderAbility.effect.type === "feelNoPain") return true
      }

      for (const globalModifier of this.globalModifiersFilteredBySelected) {
        if (globalModifier.effect.type === "feelNoPain") return true
      }

      return false
    },

    showAlertFnpFaq(state) {
      if (!this.showDamageNotIgnoredGetter) {
        return false
      }

      // Returns true if at least 1 model was slain in the current sim results.
      if (state.simulation.simResults?.count?.finalModelsSlain?.restricted) {
        let atLeast1ModelSlain = false
        state.simulation.simResults?.count.finalModelsSlain.restricted.forEach(
          (value, key) => {
            if (Number.isInteger(key) && value >= 1) {
              atLeast1ModelSlain = true
            }
          }
        )
        return atLeast1ModelSlain
      }

      return false
    },

    showSimResults(state) {
      return state.simResultsReady && this.simReqsMet
    },
    simReqsMet(state) {
      const userConfigSimsStore = useUserConfigSimsStore()
      if (
        state.updateInProgress ||
        userConfigSimsStore.totalSims < 1 ||
        userConfigSimsStore.totalSims > configSims.maxSims
      ) {
        return false
      }

      let attackerReqsMet = false
      let defenderReqsMet = false

      // Attacker reqs.

      // Ad hoc.
      if (
        state.profileUiAttacker === "adhoc" &&
        state.currentAttackerAdHoc.confirmed
      ) {
        attackerReqsMet = true
      }

      // Profile.
      if (state.profileUiAttacker === "profile") {
        // Match attacker role to attack type.
        if (!this.currentAttackerCompatibleWithAttackType) {
          return false
        }
        if (
          state.attackContext.attackType === "Melee" &&
          this.currentAttackerWeaponsSelectedFiltered.length < 1
        ) {
          // Return false if there's no melee weapons selected.
          return false
        }
        if (
          state.attackContext.attackType === "Ranged" &&
          this.currentAttackerWeaponsSelectedFiltered.length < 1
        ) {
          // Return false if there's no ranged weapons selected.
          return false
        }

        // Returns true if attacker profile has been selected.
        attackerReqsMet = !!this.currentAttacker?.id
      }

      // Defender reqs.

      // Ad hoc.
      if (
        state.profileUiDefender === "adhoc" &&
        state.currentDefenderAdHoc.confirmed
      ) {
        defenderReqsMet = true
      }

      // Profile.
      if (state.profileUiDefender === "profile") {
        // Returns true if defender profile has been selected.
        defenderReqsMet = !!state.currentDefender?.id
      }

      // Returns true if both attacker & defender reqs have been met.
      return attackerReqsMet && defenderReqsMet
    },
    simResultsKey(state) {
      let generatedKey = ""

      if (state.profileUiAttacker === "adhoc") {
        if (typeof state.currentAttackerAdHoc.profile.updated !== "undefined") {
          generatedKey += `${state.currentAttackerAdHoc.profile.updated}`
        }
      }

      if (state.profileUiDefender === "adhoc") {
        if (typeof state.currentDefenderAdHoc.profile.updated !== "undefined") {
          generatedKey += `:${state.currentDefenderAdHoc.profile.updated}`
        }
      }

      generatedKey += state.attackContext.attackType
      let attackContext = state.attackContext.ranged
      if (state.attackContext.attackType === "Melee") {
        attackContext = state.attackContext.melee
      }
      // Walk through attackContext as if it were an array.
      for (const [key, value] of Object.entries(attackContext)) {
        generatedKey += `:${String(key)}=${String(value)}`
      }

      if (state.profileUiAttacker === "profile") {
        if (
          typeof state.currentAttacker !== "undefined" &&
          typeof state.currentAttacker.id !== "undefined"
        ) {
          generatedKey += `:${state.currentAttacker.id}`
          generatedKey += `${state.currentAttacker.updated}`
          if (typeof this.currentAttackerWeaponsSelected !== "undefined") {
            this.currentAttackerWeaponsSelected.forEach((weapon) => {
              generatedKey += `:${weapon.id}`
            })
          }
          if (typeof this.currentAttackerAbilitiesSelected !== "undefined") {
            this.currentAttackerAbilitiesSelected.forEach((ability) => {
              generatedKey += `:${ability.id}:${ability.updated}`
            })
          }
        }
      }

      if (state.profileUiDefender === "profile") {
        if (
          typeof state.currentDefender !== "undefined" &&
          typeof state.currentDefender.id !== "undefined"
        ) {
          generatedKey += `:${state.currentDefender.id}`
          generatedKey += `:${state.currentDefender.updated}`
          if (typeof this.currentDefenderAbilitiesSelected !== "undefined") {
            this.currentDefenderAbilitiesSelected.forEach((ability) => {
              generatedKey += `:${ability.id}:${ability.updated}`
            })
          }
        }
      }

      if (typeof state.modifiersGlobalSelected !== "undefined") {
        state.modifiersGlobalSelected.forEach((modifier) => {
          generatedKey += `:${modifier.id}:${modifier.updated}`
        })
      }

      generatedKey += `:${state.simLastManualTriggerTime}`

      // console.log("generatedKey", generatedKey)
      return generatedKey
    },

    unitsFromProfiles(state) {
      const units = []
      state.profiles.forEach((profile) => {
        profile.units.forEach((unit) => {
          if (!unit.name) {
            unit.name = profile.name
          }
          units.push(unit)
        })
      })
      return units
    },
  },
  actions: {
    showDamageNotIgnored() {
      // TODO: This should be a getter.
      // This used to be a getter, but it wasn't returning a different result
      // when its deps changed so now it's an action.

      for (const unit of this.getDefenderForSim.units) {
        for (const modelType of unit.modelTypes) {
          if (
            typeof modelType.ignore !== "undefined" &&
            modelType?.ignore !== null
          ) {
            return true
          }
        }
      }

      for (const defenderAbility of this
        .currentDefenderAbilitiesSelectedFiltered) {
        if (defenderAbility.effect.type === "feelNoPain") return true
      }

      for (const globalModifier of this.globalModifiersFilteredBySelected) {
        if (globalModifier.effect.type === "feelNoPain") return true
      }

      return false
    },
    // Vuex mutations
    assignProfileIds() {
      this.profiles.forEach((profile) => {
        // console.log("Assigning profile ID: ", this.profileIdNext)
        profile.id = this.profileIdNext
        this.profileIdNext++
      })
      console.log("Profile IDs assigned.")
      // If IDs are reassigned it's safest/simplest to reset the current attacker / defender.
      this.currentAttacker = {}
      this.currentDefender = {}
      console.log("Current attacker & defender reset.")
    },

    deleteGlobalModifier(payload) {
      const modifiersGlobalIndex = this.modifiersGlobal.indexOf(payload)
      const modifiersGlobalSelectedIndex =
        this.modifiersGlobalSelected.indexOf(payload)
      this.modifiersGlobal.splice(modifiersGlobalIndex, 1)
      this.modifiersGlobalSelected.splice(modifiersGlobalSelectedIndex, 1)
    },
    deleteProfiles(profiles) {
      profiles.forEach((profile) => {
        // Clear currentAttacker/currentDefender if it matches the profile being deleted.
        if (profile.id === this.currentAttacker.id) {
          this.currentAttacker = {}
        }
        if (profile.id === this.currentDefender.id) {
          this.currentDefender = {}
        }
        const index = this.profiles.indexOf(profile)
        this.profiles.splice(index, 1)
      })
      this.profilesUpdated = Date.now()
    },

    initialiseProfileData() {
      console.log("Initialising profile data...")
      this.setProfiles([])
      this.setCurrentAttacker(null)
      this.setCurrentDefender(null)
      this.profileIdNext = 1
      this.profilesUpdated = Date.now()
      console.log("Profile data initialised.")
    },
    initModifiersGlobal() {
      this.modifiersGlobal = []
      this.modifierGlobalIdNext = 1
      this.modifiersGlobalSelected = []
    },

    loadProfiles(incomingProfiles) {
      const profiles = cloneDeep(incomingProfiles)
      this.initialiseProfileData()
      console.log("Loading profile data...")
      // Write profiles to store.
      this.setProfiles(profiles)
      console.log("Profile data loaded.")
      this.readOnlyProfiles = true
      this.assignProfileIds()
      this.timestampProfiles()
      this.readOnlyProfiles = false
    },

    resetCurrentAttackerAdHoc() {
      this.currentAttackerAdHoc.profile = {}
      this.currentAttackerAdHoc.confirmed = false
    },
    resetCurrentDefenderAdHoc() {
      this.currentDefenderAdHoc.profile = {}
      this.currentDefenderAdHoc.confirmed = false
    },

    setCurrentAttackerAdHoc(payload) {
      this.currentAttackerAdHoc.profile = payload
      this.currentAttackerAdHoc.confirmed = true
    },
    setCurrentDefenderAdHoc(payload) {
      this.currentDefenderAdHoc.profile = payload
      this.currentDefenderAdHoc.confirmed = true
    },

    setCurrentAttackerAbilitiesSelected(payload) {
      const selectedAbilitiesIds = payload.selectedAbilitiesIds
      const sourceProfile = this.profiles.find((profile) => {
        return profile.id === payload.profileId
      })
      // Clear relevant abilitiesSelected arrays.
      this.currentAttacker.abilitiesSelected = []
      sourceProfile.abilitiesSelected = []
      // Re-populate relevant abilitiesSelected arrays in a way that is reactive (i.e. using push()).
      selectedAbilitiesIds.forEach((selectedAbilityId) => {
        this.currentAttacker.abilitiesSelected.push(selectedAbilityId)
        sourceProfile.abilitiesSelected.push(selectedAbilityId)
      })
    },
    setCurrentDefenderAbilitiesSelected(payload) {
      const abilitiesData = payload.abilitiesData
      const sourceProfile = this.profiles.find((profile) => {
        return profile.id === payload.profileId
      })
      // Clear relevant abilitiesSelected arrays.
      this.currentDefender.abilitiesSelected = []
      sourceProfile.abilitiesSelected = []
      // Re-populate relevant abilitiesSelected arrays in a way that reactive (i.e.using push()).
      abilitiesData.forEach((selectedAbilityId) => {
        this.currentDefender.abilitiesSelected.push(selectedAbilityId)
        sourceProfile.abilitiesSelected.push(selectedAbilityId)
      })
    },
    setCurrentAttackerWeaponsSelected(payload) {
      const sourceProfile = this.profiles.find((profile) => {
        return profile.id === payload.profileId
      })
      // Clear relevant weaponsSelected arrays.
      const attackType = this.attackContext.attackType.toLowerCase()
      if (attackType === "melee") {
        this.currentAttacker.weaponsSelected.melee = []
        sourceProfile.weaponsSelected.melee = []
      } else {
        this.currentAttacker.weaponsSelected.ranged = []
        sourceProfile.weaponsSelected.ranged = []
      }
      // Re-populate relevant weaponsSelected arrays in a way that is reactive (i.e. using push()).
      payload.selectedWeaponsIds.forEach((selectedWeaponId) => {
        this.currentAttacker.weaponsSelected[attackType].push(selectedWeaponId)
        sourceProfile.weaponsSelected[attackType].push(selectedWeaponId)
      })
    },
    setCurrentDefender(profile) {
      if (profile === null) {
        this.currentDefender = profile
      } else {
        this.currentDefender = mergeDefaultsInToProfile(cloneDeep(profile))
      }
    },
    setCurrentAttackerHandleWeaponsSelected(payload) {
      const { profile, markCurrentWeaponAsSelected, weaponId } = payload

      if (profile === null) {
        this.currentAttacker = profile
      } else {
        this.currentAttacker = mergeDefaultsInToProfile(cloneDeep(profile))
      }

      if (markCurrentWeaponAsSelected) {
        const attackType = this.attackContext.attackType.toLowerCase()
        // Current attacker has no weapons for the current attack type selected AND
        // they only have one weapon that is compatible with the current attack type.
        // Automatically mark it as selected.
        this.currentAttacker.weaponsSelected[attackType].push(weaponId)
        // Find the source profile in the profiles array and apply the change there too.
        const sourceProfile = this.profiles.find(
          (profile) => profile.id === this.currentAttacker.id
        )
        if (typeof sourceProfile.weaponsSelected === "undefined") {
          sourceProfile.weaponsSelected = {
            melee: [],
            ranged: [],
          }
        }
        sourceProfile.weaponsSelected[attackType].push(weaponId)
      }
    },

    setProfiles(payload) {
      this.profiles = payload
      this.profilesUpdated = Date.now()
    },
    setProfileUi(payload) {
      const { profileType, profileUiType } = payload
      if (profileType === "attacker") {
        this.profileUiAttacker = profileUiType
      }
      if (profileType === "defender") {
        this.profileUiDefender = profileUiType
      }
    },

    syncAppVersions() {
      const runtimeConfig = useRuntimeConfig()
      const envPackageVersion = runtimeConfig.public.clientVersion
      // Check if version in package.json is > appVersionLastSeen.
      if (semverGt(envPackageVersion, this.appVersionLastSeen)) {
        // Display alert regarding the new version release.
        this.alertNewVersion = true
        // Write current app version from package.json to store.
        this.appVersionLastSeen = envPackageVersion
        console.log(`App version sync complete: ${envPackageVersion}`)
      } else {
        // If version in package.json is <= appVersionLastSeen,
        // make sure that the new version alert is no longer shown.
        this.alertNewVersion = false
      }
    },

    timestampProfiles() {
      console.log("Updating profile timestamps...")
      const time = timestamp()
      this.profiles.forEach((profile) => {
        profile.created = time
        profile.updated = profile.created
      })
      console.log("Profile timestamps updated.")
    },

    updateModifiersGlobal(options = {}) {
      // Applies any pending updates to global modifiers.

      const {
        applyAllUpdates = false,
        applyLatestUpdate = false,
        versionToUpdateFrom,
      } = options

      console.log("\nUpdating global modifiers...\n\n")

      this.modifiersGlobal = this.modifiersGlobal.map((modifier) => {
        return updateModifier(modifier, {
          applyAllUpdates,
          applyLatestUpdate,
          versionToUpdateFrom,
        })
      })
    },
    updateLastSeen() {
      this.lastSeen = Date.now()
    },
    updateProfiles(options = {}) {
      // Applies any pending updates to profile data.

      const {
        applyAllUpdates = false,
        applyLatestUpdate = false,
        versionToUpdateFrom,
      } = options

      console.log("\nUpdating profiles...\n\n")
      this.readOnlyProfiles = true

      this.checkDataIntegrity()

      // Loop through profiles
      this.profiles.forEach((profile, profileIndex) => {
        const profileProcessed = updateProfile(profile, {
          applyAllUpdates,
          applyLatestUpdate,
          versionToUpdateFrom,
        })

        // Check if the current profile was updated.
        let setAsCurrent = null
        if (profileProcessed.updateStatus.updated) {
          // Check if the updated profile was set as the current attacker or defender.
          if (typeof this.currentAttacker.id !== "undefined") {
            if (profileProcessed.id === this.currentAttacker.id) {
              setAsCurrent = "Attacker"
            }
          }
          if (typeof this.currentDefender.id !== "undefined") {
            if (profileProcessed.id === this.currentDefender.id) {
              setAsCurrent = "Defender"
            }
          }

          // We no longer need the updateStatus data, delete the property.
          delete profileProcessed.updateStatus

          // console.log("profileProcessed", profileProcessed)
          // Write the updated profile data.
          this.writeProfile({
            isClone: false,
            index: profileIndex,
            profile: profileProcessed,
            setAsCurrent,
          })
        }
      })

      // UX nicety.
      // If there is a current attacker set...
      if (typeof this.currentAttacker.id !== "undefined") {
        // and that attacker doesn't have the "Attacker: Melee" role...
        if (!this.currentAttacker.computed.roles.includes("Attacker: Melee")) {
          // set the attack type to "Ranged".
          this.attackContext.attackType = "Ranged"
        }
      }

      this.readOnlyProfiles = false
      console.log("\nProfiles updated.\n\n")
    },
    checkDataIntegrity() {
      console.log("Checking data integrity...")
      if (typeof this.profiles.attackers !== "undefined") {
        console.log("Legacy profiles architecture detected.")
        this.migrateProfiles()
      }

      // Returns true if profile.id is of type "number", otherwise returns false.
      const hasId = (profile) => {
        return typeof profile.id === "number"
      }

      let profileIdIssueDetected = false

      if (this.profiles.length) {
        if (!this.profiles.every(hasId)) {
          profileIdIssueDetected = true
        }
      }

      this.profiles.forEach((profile) => {
        // Profile IDs used to start at 0 but now start at 1.
        // If a profile is found with an ID of 0, reassign all profile IDs.
        if (profile.id === 0) {
          profileIdIssueDetected = true
        }
      })

      if (profileIdIssueDetected) {
        console.log("Profile IDs need to be updated...")
        this.assignProfileIds()
      }
    },
    migrateProfiles() {
      console.log("Migrating profiles...")
      // Deselect current attacker/defender profiles.
      this.setCurrentAttacker(null)
      this.setCurrentDefender(null)
      // Copy legacy profiles to new object.
      const profilesLegacy = cloneDeep(this.profiles)
      const time = timestamp()
      const migratedProfiles = []
      // Iterate through legacy attacker profiles.
      profilesLegacy.attackers.forEach((profile) => {
        // Add missing properties.
        profile.roles = ["Attacker"]
        profile.ignore = null
        profile.invuln = null
        profile.keywords = []
        profile.modelCount = null
        profile.save = null
        profile.toughness = null
        profile.wounds = null
        profile.created = time
        profile.updated = profile.created
        // Push to migratedProfiles array.
        migratedProfiles.push(profile)
      })
      // Iterate through legacy attacker profiles.
      profilesLegacy.defenders.forEach((profile) => {
        // Add missing properties.
        profile.roles = ["Defender"]
        profile.bs = null
        profile.points = null
        profile.weapons = []
        profile.created = time
        profile.updated = profile.created
        if (profile.ignore === false) {
          profile.ignore = null
        }
        if (profile.invuln === false) {
          profile.invuln = null
        }
        // Push to migratedProfiles array.
        migratedProfiles.push(profile)
      })
      // Convert store profiles from an object of 2 arrays (attacker/defender)
      // to just a single flat array of profiles.
      this.setProfiles(migratedProfiles)
      console.log("Profiles migrated.")
    },
    updateProfileSelectedAbilities(profile) {
      // Ensures that profile.abilitiesSelected only contains IDs for abilities that still exist on the profile.

      // Return early if profile.abilitiesSelected is undefined or empty.
      if (
        typeof profile.abilitiesSelected === "undefined" ||
        !profile.abilitiesSelected.length
      ) {
        return
      }

      // Clone profile.abilitiesSelected so that we can edit an array of selected abilities
      // without affecting the array that we're iterating through
      // (which could potentially alter indices as we're iterating).
      const abilitiesSelectedTempClone = cloneDeep(profile.abilitiesSelected)

      profile.units.forEach((unit) => {
        // Create convenience var: array of IDs of abilities this unit has.
        const abilityIds = unit.abilities.map((ability) => ability.id)

        profile.abilitiesSelected.forEach((selectedAbilityId) => {
          if (!abilityIds.includes(selectedAbilityId)) {
            // Profile no longer has this ability, remove it from abilitiesSelectedTempClone
            const indexOfAbilityIdToRemove =
              abilitiesSelectedTempClone.indexOf(selectedAbilityId)
            abilitiesSelectedTempClone.splice(indexOfAbilityIdToRemove, 1)
          }
        })
      })

      // Once we've finished iterating, update profile.abilitiesSelected with the results of the process.
      // Start by clearing the abilitiesSelected array.
      profile.abilitiesSelected = []
      // Then re-populate the abilitiesSelected array in way that is reactive (e.g. using push())
      abilitiesSelectedTempClone.forEach((selectedAbility) => {
        profile.abilitiesSelected.push(selectedAbility)
      })
    },
    updateProfileSelectedWeapons(profile) {
      // Ensures that profile.weaponsSelected only contains IDs for weapons that still exist on the profile.

      // Return early if profile.weaponsSelected is undefined.
      if (typeof profile.weaponsSelected === "undefined") {
        return
      }
      // Return early if profile.weaponsSelected is missing any required properties.
      if (
        typeof profile.weaponsSelected.melee === "undefined" ||
        typeof profile.weaponsSelected.ranged === "undefined"
      ) {
        return
      }

      // Clone profile.weaponsSelected so that we can edit an array of selected weapons
      // without affecting the array that we're iterating through
      // (which could potentially alter indices as we're iterating).
      const weaponsSelectedTempClone = cloneDeep(profile.weaponsSelected)

      // Create an array of IDs of weapons this profile's modelTypes have.
      let weaponIds = []
      profile.units.forEach((unit) => {
        for (let modelType of unit.modelTypes) {
          if (typeof modelType.weapons === "undefined") {
            continue
          }
          weaponIds = [
            ...modelType.weapons.map((weapon) => weapon.id),
            ...weaponIds,
          ]
        }
      })

      // Selected melee weapons.
      profile.weaponsSelected.melee.forEach((selectedWeaponId) => {
        if (!weaponIds.includes(selectedWeaponId)) {
          // Profile no longer has this weapon, remove it from weaponsSelectedTempClone
          const indexOfWeaponIdToRemove =
            weaponsSelectedTempClone.melee.indexOf(selectedWeaponId)
          weaponsSelectedTempClone.melee.splice(indexOfWeaponIdToRemove, 1)
        }
      })

      // Selected ranged weapons.
      profile.weaponsSelected.ranged.forEach((selectedWeaponId) => {
        if (!weaponIds.includes(selectedWeaponId)) {
          // Profile no longer has this weapon, remove it from weaponsSelectedTempClone
          const indexOfWeaponIdToRemove =
            weaponsSelectedTempClone.ranged.indexOf(selectedWeaponId)
          weaponsSelectedTempClone.ranged.splice(indexOfWeaponIdToRemove, 1)
        }
      })

      // Once we've finished iterating, update profile.weaponsSelected with the results of the process.
      // Start by initialising the weaponsSelected array.
      profile.weaponsSelected = {
        melee: [],
        ranged: [],
      }
      // Then re-populate the weaponsSelected arrays in way that is reactive (e.g. using push())
      weaponsSelectedTempClone.melee.forEach((selectedWeapon) => {
        profile.weaponsSelected.melee.push(selectedWeapon)
      })
      weaponsSelectedTempClone.ranged.forEach((selectedWeapon) => {
        profile.weaponsSelected.ranged.push(selectedWeapon)
      })
    },

    writeToModifiersGlobal(payload) {
      // Check if payload modifier already exists in this.modifiersGlobal & this.modifiersGlobalSelected
      // and get indices.
      let modifier = payload
      const modifiersGlobalIndex = this.modifiersGlobal.findIndex(
        (existingModifier) => existingModifier.id === modifier.id
      )
      const modifiersGlobalSelectedIndex =
        this.modifiersGlobalSelected.findIndex(
          (existingModifier) => existingModifier.id === modifier.id
        )
      if (modifiersGlobalIndex >= 0) {
        // This is an existing modifier. Overwrite at the correct existing index in both arrays.
        this.modifiersGlobal.splice(modifiersGlobalIndex, 1, modifier)
        this.modifiersGlobalSelected.splice(
          modifiersGlobalSelectedIndex,
          1,
          modifier.id
        )
      } else {
        // This is a new modifier.
        // It could be from a preset, or it could be newly created from scratch.
        if (modifier.id < 0) {
          // This is a new modifier from a preset (presets have negative IDs).
          // Clone the modifier, breaking any references to the original payload object.
          modifier = cloneDeep(payload)
          // Assign the clone a new unique ID.
          modifier.id = this.modifierGlobalIdNext
          // Override the scope to be just ["global"].
          // The preset might have listed multiple possible scopes.
          modifier.scope = ["global"]
          this.modifierGlobalIdNext++
        }
        // Push to both arrays.
        this.modifiersGlobal.push(modifier)
        this.modifiersGlobalSelected.push(modifier.id)
      }
    },

    // Vuex actions
    confirmAdHocProfile(payload) {
      const { profile, profileType } = payload
      if (profileType === "attacker") {
        this.setCurrentAttackerAdHoc(profile)
      }
      if (profileType === "defender") {
        this.setCurrentDefenderAdHoc(profile)
      }
      // Try to run sim.
      this.tryRunSim("auto")
    },
    setCurrentAttacker(profile) {
      // console.log("setCurrentAttacker()", profile)

      let markCurrentWeaponAsSelected = false
      let weaponId = null

      if (
        typeof this.currentAttacker.weapons !== "undefined" &&
        typeof this.currentAttacker.weaponsSelected !== "undefined"
      ) {
        if (
          this.currentAttackerWeaponsSelectedFiltered.length === 0 &&
          this.currentAttackerWeaponsFiltered.length === 1
        ) {
          // Current attacker has no weapons for the current attack type selected AND
          // they only have one weapon that is compatible with the current attack type.
          // Automatically mark it as selected.
          markCurrentWeaponAsSelected = true
          weaponId = this.currentAttackerWeaponsFiltered[0].id
        }
      }

      const payload = {
        profile,
        markCurrentWeaponAsSelected,
        weaponId,
      }

      // TODO: Not using Vuex anymore - how relevant is this still?
      // We then pass this all off to be handled by a mutation.
      // Handling in this action means that the updated currentAttacker object doesn't get written to local storage :(
      // (and we can't do it all in a mutation as they can't access getters).
      // Credit: https://forum.vuejs.org/t/vuex2-using-getters-inside-mutations/2788/2
      this.setCurrentAttackerHandleWeaponsSelected(payload)
    },
    async tryRunSim(trigger) {
      // It's tempting to check this.simulationRunning as part of this condition
      // but doing so completely messes with e2e testing (so don't bother).

      // console.log("tryRunSim() trigger:", trigger)

      this.simTriggerLast = trigger

      /*
       * Return early if:
       * - totalSims is < 1,
       * - attempting to auto-run sims over the max auto-run totalSims value,
       * - attempting to auto-run sims when auto-run is un-checked.
       * */

      const userConfigSimsStore = useUserConfigSimsStore()

      if (
        userConfigSimsStore.totalSims < 1 ||
        userConfigSimsStore.totalSims > configSims.maxSims
      ) {
        // Open "Simulations" panel and close the others.
        const storePageCrunchSingle = usePageCrunchSingleStore()
        storePageCrunchSingle.panelsCrunchSingle = [4]
        return
      }

      if (trigger === "auto") {
        const userConfigSimsStore = useUserConfigSimsStore()
        if (!userConfigSimsStore.autoRunSims) {
          return
        }
        if (
          userConfigSimsStore.autoRunSims &&
          userConfigSimsStore.totalSims > configSims.maxAutoRunSims
        ) {
          return
        }
      }
      // Check if sim requirements are met.
      if (!this.simReqsMet) {
        return
      }

      // Clear any sim error.
      this.simError = null

      // Record time of last manual trigger (used in simResultsKey getter).
      if (trigger === "manual") {
        this.simLastManualTriggerTime = Date.now()
      }

      // Run sims if the simResultsKey no longer matches the cached simResultsKey.
      if (this.simResultsKeyCached !== this.simResultsKey) {
        // Cache the current simResultsKey.
        this.simResultsKeyCached = this.simResultsKey
      }
    },
    endSim() {
      this.simulationRunning = false
      this.simResultsReadyTime = Date.now()
      this.simResultsReady = true
    },
    updateCurrentProfiles() {
      if (typeof this.currentAttacker.id !== "undefined") {
        // console.log("Updating current attacker profile data...")
        const currentAttackerId = this.currentAttacker.id
        const latestAttackerData = this.profiles.find((profile) => {
          if (profile.id === currentAttackerId) {
            return profile
          }
        })
        this.setCurrentAttacker(latestAttackerData)
        // console.log("Current attacker profile data updated.")
      }
      if (typeof this.currentDefender.id !== "undefined") {
        // console.log("Updating current defender profile data...")
        const currentDefenderId = this.currentDefender.id
        const latestDefenderData = this.profiles.find(
          (profile) => profile.id === currentDefenderId
        )
        this.setCurrentDefender(latestDefenderData)
        // console.log("Current defender profile data updated.")
      }
    },
    writeProfile({ isClone, index, profile, setAsCurrent }) {
      const profileCloned = cloneDeep(profile)
      let profileCleaned = null

      if (index > -1) {
        // Update Existing profile.

        // Assign IDs to new weapons.
        profileCloned.units.forEach((unit) => {
          unit.modelTypes.forEach((modelType) => {
            if (typeof modelType.weapons !== "undefined") {
              modelType.weapons.forEach((weapon) => {
                // New weapons start with negative IDs.
                if (weapon.id <= -1) {
                  weapon.id = Number(profileCloned.weaponIdNext)
                  profileCloned.weaponIdNext++
                  // Add weaponsSelected object to profile if it's missing.
                  if (typeof profileCloned.weaponsSelected === "undefined") {
                    profileCloned.weaponsSelected = {
                      melee: [],
                      ranged: [],
                    }
                  }
                  // Mark weapon as selected by default.
                  profileCloned.weaponsSelected[weapon.type.toLowerCase()].push(
                    weapon.id
                  )
                }
              })
            }
          })
        })

        profileCleaned = cleanProfile(profileCloned)

        // Vue.set(this.profiles, index, profileCleaned)
        this.profiles.splice(index, 1, profileCleaned)

        // Ensure selected weapons & abilities are kept up to date.
        this.updateProfileSelectedWeapons(profileCleaned)
        this.updateProfileSelectedAbilities(profileCleaned)
      }

      if (index <= -1) {
        // Add new profile.
        profileCloned.created = profileCloned.updated
        profileCloned.id = this.profileIdNext
        this.profileIdNext++
        // Process weapons.
        // Cloned profiles have an index of -1 just like new profiles.
        // The following weapons processing should only be applied to new, non-cloned profiles.
        if (!isClone) {
          profileCloned.weaponsSelected = {
            melee: [],
            ranged: [],
          }
          profileCloned.units.forEach((unit) => {
            unit.modelTypes.forEach((modelType) => {
              modelType.weapons.forEach((weapon) => {
                // Assign ID to weapon and mark it as selected by default
                // (all weapons will be new as it's a new profile).
                weapon.id = Number(profileCloned.weaponIdNext)
                profileCloned.weaponIdNext++
                profileCloned.weaponsSelected[weapon.type.toLowerCase()].push(
                  weapon.id
                )
              })
            })
          })
        }

        profileCleaned = cleanProfile(profileCloned)

        this.profiles.push(profileCleaned)
      }

      if (
        setAsCurrent === "Attacker" ||
        profileCleaned?.id === this.currentAttacker.id
      ) {
        // Update the currently selected attacker profile.
        this.setCurrentAttacker(profileCleaned)
      }
      if (
        setAsCurrent === "Defender" ||
        profileCleaned?.id === this.currentDefender.id
      ) {
        // Update the currently selected defender profile.
        this.setCurrentDefender(profileCleaned)
      }
      if (
        typeof this.currentAttacker.id !== "undefined" &&
        typeof this.currentDefender.id !== "undefined" &&
        this.currentAttacker.id === this.currentDefender.id
      ) {
        // The same profile is selected as both the current attacker and current defender.
        // Update both.
        this.setCurrentAttacker(profileCleaned)
        this.setCurrentDefender(profileCleaned)
      }
      this.profilesUpdated = Date.now()
    },
  },
  persist: {
    key: "unitcrunch",
    storage: piniaPluginPersistedstate.localStorage(),
    pick: [
      "alertExplainScopeGlobal",
      "appVersionLastSeen",
      "attackContext",
      "averageType",
      "currentAttacker",
      "currentAttackerAdHoc",
      "currentDefender",
      "currentDefenderAdHoc",
      "lastSeen",
      "modifierGlobalIdNext",
      "modifiersGlobal",
      "modifiersGlobalSelected",
      "newUser",
      "profileIdNext",
      "profileUiAttacker",
      "profileUiDefender",
      "profiles",
    ],
  },
})
