export function useVuelidateErrorsWeapon(v$) {
  const errorsAp = () => {
    const errors = []
    if (!v$.value.weapon.ap.$dirty) return errors
    // Message differs from rule logic on purpose here as we technically allow values of 0-6
    // but then convert them to negative numbers on save.
    v$.value.weapon.ap.between.$invalid &&
      errors.push("AP must be between 0 and -6")
    // TODO: "integer" test seems to always get beat by the "required" test.
    // Likely related to the model always being a string, even in a number input.
    v$.value.weapon.ap.integer.$invalid && errors.push("AP must be a number")
    v$.value.weapon.ap.required.$invalid && errors.push("Value required")
    return errors
  }

  const errorsDamage = () => {
    const errors = []
    if (!v$.value.weapon.damage.$dirty) return errors
    v$.value.weapon.damage.required.$invalid && errors.push("Value required")
    v$.value.weapon.damage.notationDice.$invalid &&
      errors.push("Invalid dice notation")
    return errors
  }

  const errorsStrength = () => {
    const errors = []
    if (!v$.value.weapon.strength.$dirty) return errors
    v$.value.weapon.strength.required.$invalid && errors.push("Value required")
    v$.value.weapon.strength.notationDice.$invalid &&
      errors.push("Invalid dice notation")
    return errors
  }

  const errorsAttacks = () => {
    const errors = []
    if (!v$.value.weapon.attacks.$dirty) return errors
    v$.value.weapon.attacks.required.$invalid && errors.push("Value required")
    v$.value.weapon.attacks.notationDice.$invalid &&
      errors.push("Invalid notation")
    return errors
  }

  return {
    errorsAp,
    errorsDamage,
    errorsStrength,
    errorsAttacks,
  }
}
