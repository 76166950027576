<script setup>
import { useGlobalStore } from "@/stores/global"
const store = useGlobalStore()
</script>

<template>
  <div class="attack-type-selector">
    <div class="attack-type-selector__label text-caption mr-2">
      Attack type:
    </div>
    <v-chip-group
      v-model="store.attackContext.attackType"
      mandatory
      selected-class="selected"
    >
      <v-chip value="Melee" data-cy="attack-type-melee" label @click.stop>
        Melee
      </v-chip>
      <v-chip value="Ranged" data-cy="attack-type-ranged" label @click.stop>
        Ranged
      </v-chip>
    </v-chip-group>
  </div>
</template>

<style lang="scss" scoped>
.attack-type-selector {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: -4px;
}
</style>
