import { upperFirst } from "es-toolkit"

const specialAdjectives = [
  "automatic",
  "double",
  "greater",
  "heavy",
  "icarus",
  "infinity",
  "interdimensional",
  "intergalactic",
  "master-crafted",
  "mega",
  "relic",
  "super",
  "triple",
  "turbo",
  "twin",
  "ultra",
]

const adjectives = [
  "beam",
  "blunder",
  "bungle",
  "cardiac",
  "cranial",
  "daemon",
  "doom",
  "dung",
  "electro",
  "force",
  "gauss",
  "graviton",
  "hellfire",
  "jank",
  "laser",
  "lightning",
  "magma",
  "methane",
  "mind",
  "party",
  "phase",
  "phosphor",
  "photon",
  "power",
  "pulse",
  "rad",
  "radium",
  "scatter",
  "scrumpy",
  "shock",
  "shuriken",
  "sonic",
  "space",
  "splinter",
  "stealth",
  "terror",
  "tesla",
  "thunder",
  "void",
  "warp",
]

const nouns = [
  "bagpipes",
  "battery",
  "beamer",
  "belcher",
  "bellows",
  "belter",
  "blades",
  "blaster",
  "blender",
  "bouzouki",
  "cannon",
  "carbine",
  "catapult",
  "cleanser",
  "club",
  "crusher",
  "culverin",
  "decimator",
  "disruptor",
  "drill",
  "emitter",
  "fist",
  "flamer",
  "flinger",
  "fork",
  "fusil",
  "hammer",
  "harpoon",
  "hunter",
  "impulsor",
  "injector",
  "jammer",
  "lance",
  "launcher",
  "lobber",
  "lute",
  "mace",
  "melta",
  "missiles",
  "mortar",
  "needle",
  "pistol",
  "probe",
  "projector",
  "prong",
  "pulsar",
  "raker",
  "razor",
  "repeater",
  "resonator",
  "rifle",
  "ringer",
  "ripper",
  "saber",
  "scythe",
  "shaker",
  "shredder",
  "slinger",
  "smasher",
  "spear",
  "stalker",
  "stinger",
  "syphon",
  "taser",
  "toaster",
  "torch",
  "trumpet",
  "veuglaire",
  "zappa",
]

export default function randomWeaponName() {
  // Returns a randomly generated weapon name.

  // specialAdjective

  // Generate a random number between min & max.
  const max = 12
  const min = 1
  const randomInt = Math.floor(Math.random() * (max - min) + min)
  let specialAdjective = ""
  // Generate a specialAdjective occasionally.
  if (randomInt > 4) {
    specialAdjective =
      specialAdjectives[Math.floor(Math.random() * specialAdjectives.length)] +
      " "
  }
  // Generate a second specialAdjective very rarely.
  if (randomInt > 9) {
    let secondSpecialAdjective =
      specialAdjectives[Math.floor(Math.random() * specialAdjectives.length)] +
      " "
    // Ensure it doesn't match the first specialAdjective.
    // TODO: This doesn't always work apparently.
    if (secondSpecialAdjective === specialAdjective) {
      secondSpecialAdjective =
        specialAdjectives[
          Math.floor(Math.random() * specialAdjectives.length)
        ] + " "
    }
    specialAdjective += secondSpecialAdjective
  }

  // adjective

  const adjective =
    adjectives[Math.floor(Math.random() * adjectives.length)] + " "

  // noun

  const noun = nouns[Math.floor(Math.random() * nouns.length)]

  // Combine adjective & noun, apply sentence case.

  return upperFirst(`${specialAdjective}${adjective}${noun}`.toLowerCase())
}
