<script setup>
import { formatThousandsSeparator } from "~/utils/utils"
import { useGlobalStore } from "@/stores/global"
import { between } from "@vuelidate/validators"
import { vMaska } from "maska/vue"
import useVuelidate from "@vuelidate/core"
import { configSims } from "~/utils/config"
import UcBtn from "@/components/UC/UcBtn.vue"
import UcTextField from "@/components/UC/UcTextField.vue"
import UcCheckbox from "@/components/UC/UcCheckbox.vue"
import { computed } from "vue"
import { Icon } from "@iconify/vue"
import { useUserConfigSimsStore } from "@/stores/userConfigSims"

const storeUserConfigSims = useUserConfigSimsStore()
const store = useGlobalStore()

// Adding formatThousandsSeparator, here makes it available within the template.
// formatThousandsSeparator,
const maxAutoRunSims = configSims.maxAutoRunSims
const maxSims = configSims.maxSims

const totalSims = computed({
  get() {
    return Number(storeUserConfigSims.totalSims)
  },
  set(value) {
    storeUserConfigSims.totalSims = Number(value)
  },
})

const validations = computed(() => ({
  totalSims: {
    between: between(1, configSims.maxSims),
  },
}))

const v$ = useVuelidate(validations, { totalSims })

const disableRunSimsButton = computed(() => {
  v$.value.$touch()
  return (
    v$.value.$invalid ||
    !store.simReqsMet ||
    totalSims.value < 1 ||
    totalSims.value > configSims.maxSims
  )
})

const panelLabel = computed(() => {
  let label = "Simulations"
  if (totalSims.value && totalSims.value > 0) {
    label += `: ${formatThousandsSeparator(totalSims.value)}`
  }
  return label
})

function errorsTotalSims() {
  const errors = []
  if (!v$.value.totalSims.$dirty) return errors
  v$.value.totalSims.between.$invalid &&
    errors.push("Must be between 1 and 1,000,000")
  return errors
}

function tryRunSim() {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    store.tryRunSim("manual")
  }
}
</script>

<template>
  <v-expansion-panel class="expansion-panel-sims">
    <v-expansion-panel-title data-cy="btn-toggle-exp-panel-sims">
      <div class="header-inner">
        <div class="header-icon">
          <Icon icon="mdi:animation-play" height="22" />
        </div>
        <div class="header-label">{{ panelLabel }}</div>
      </div>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-form class="pt-2" @submit.prevent="tryRunSim()">
        <div class="content-inner">
          <div>
            <p>
              UnitCrunch performs multiple attack sequence simulations to
              calculate the most frequent outcomes of each step as well as the
              overall results.
            </p>
            <p>
              The more simulations you perform, the more consistent the results
              will be (but the longer it will take). 10,000 is pretty quick on
              most systems and is a good balance between speed and variance.
            </p>
            <p>
              FAQ:
              <NuxtLink to="/faq/determining-results"
                >How does UnitCrunch calculate results?
              </NuxtLink>
            </p>
          </div>

          <div>
            <UcTextField
              v-model="totalSims"
              v-maska
              label="Simulations to perform"
              type="number"
              min="1"
              :max="maxSims"
              :error-messages="errorsTotalSims()"
              outlined
              data-cy="input-total-sims"
              data-maska="N"
              data-maska-tokens="N:[0-9]:multiple"
              @input="v$.totalSims.$touch()"
              @blur="v$.totalSims.$touch()"
            />

            <UcCheckbox
              v-model="storeUserConfigSims.autoRunSims"
              :label="`Auto re-run simulations of ${formatThousandsSeparator(
                maxAutoRunSims
              )} or less`"
              color="white"
              class="mt-0 pt-0"
              data-cy="checkbox-auto-run-sims"
            />

            <div class="btn-wrapper">
              <UcBtn
                type="submit"
                large
                color="primary"
                :loading="store.simulationRunning"
                :disabled="disableRunSimsButton"
                data-cy="btn-run-sims-manual"
              >
                <Icon icon="mdi:animation-play" height="18" class="mr-2" />
                Run simulations
              </UcBtn>
            </div>
          </div>
        </div>
      </v-form>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<style lang="scss" scoped>
.header-inner {
  display: flex;
  align-items: center;
}
.header-icon {
  flex: 0 1 auto;
  margin-right: 24px;
}
.header-label {
  flex: 1 1 auto;
}

.content-inner {
  display: grid;
  grid-gap: 24px;
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  @media (min-width: 600px) {
    justify-content: flex-start;
  }
}
</style>
