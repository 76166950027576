export default [
  "AIRCRAFT",
  "CHARACTER",
  "DAEMON",
  "FLY",
  "FORTIFICATION",
  "INFANTRY",
  "MONSTER",
  "PSYKER",
  "SWARM",
  "TITANIC",
  "TYRANIDS",
  "VEHICLE",
  "WALKER",
]
