<script setup>
import { computed } from "vue"

const props = defineProps({
  chips: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
  },
  errorMessages: {
    type: [Array, String],
    default: () => {
      return []
    },
  },
  itemTitle: {
    type: String,
    default: "title",
  },
  items: {
    type: Array,
    required: true,
  },
  hideDetails: {
    type: [Boolean, String],
  },
  hint: {
    type: String,
  },
  label: {
    type: String,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  persistentHint: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  returnObject: {
    type: Boolean,
    default: false,
  },
  text: {
    type: Boolean,
  },
  tile: {
    type: Boolean,
  },
  to: {
    type: [Object, String],
  },
  modelValue: {},
})

const emit = defineEmits([
  "blur",
  "change",
  "click",
  "input",
  "update:modelValue",
])

const labelProcessed = computed(() => {
  if (props.required) {
    return props.label + " *"
  }
  return props.label
})

const localValue = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit("update:modelValue", v)
  },
})
</script>

<template>
  <!-- TODO: v-select no longer supports many of the events we have defined here.  -->
  <!-- See: https://vuetifyjs.com/en/api/v-select/#events -->
  <v-select
    v-model="localValue"
    :chips="chips"
    :clearable="clearable"
    :disabled="disabled"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :item-title="itemTitle"
    :items="items"
    :label="labelProcessed"
    :multiple="multiple"
    variant="outlined"
    :persistent-hint="persistentHint"
    :required="required"
    :return-object="returnObject"
    @blur="emit('blur', $event)"
    @click="emit('click', $event)"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #item="{ props }">
      <v-list-item v-bind="props" class="v-list-item__title" />
    </template>
  </v-select>
</template>
