<script setup>
import UcBtn from "@/components/UC/UcBtn.vue"
import UcTextField from "@/components/UC/UcTextField.vue"
import { ref } from "vue"
import { Icon } from "@iconify/vue"

defineProps({
  text: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(["update"])

const edit = ref(false)
const textTemp = ref(null)

function cancel() {
  edit.value = false
}

function handleClickEdit() {
  edit.value = !edit.value
}

function submit() {
  edit.value = false
  emit("update", textTemp.value)
}
</script>

<template>
  <span>
    <span v-if="!edit" class="text-wrapper" data-cy="editable-text--edit">
      <span @click="handleClickEdit">{{ text }}</span>
      <Icon
        icon="mdi:pencil"
        :inline="true"
        class="icon-edit ml-2"
        @click="handleClickEdit"
      />
    </span>
    <v-form v-if="edit" class="form" @submit.prevent="submit">
      <UcTextField
        v-model="textTemp"
        single-line
        hide-details
        autofocus
        placeholder="More sensible weapon name"
        class="text-input"
        density="compact"
        data-cy="editable-text--input"
        @keyup.esc="cancel"
      />
      <UcBtn
        type="submit"
        size="small"
        class="ml-2"
        data-cy="editable-text--submit"
      >
        <Icon icon="mdi:check" />
      </UcBtn>
      <UcBtn
        size="small"
        class="ml-2"
        data-cy="editable-text--cancel"
        @click="cancel"
      >
        <Icon icon="mdi:close" />
      </UcBtn>
    </v-form>
  </span>
</template>

<style lang="scss" scoped>
.text-wrapper {
  display: flex;
  align-items: center;
  &:hover {
    .icon-edit {
      color: rgb(var(--v-theme-primary));
    }
  }
}
.icon-edit {
  &:hover {
    cursor: pointer;
  }
}
.form {
  display: flex;
  align-items: center;
  max-width: 500px;
}
.text-input {
  margin: 0;
  padding: 0;
}
</style>
