export default function migrateProfile(profile) {
  // Ensure all units have an abilities array, even if it's empty.

  if (typeof profile.units === "undefined") {
    return profile
  }

  profile.units.forEach((unit) => {
    if (typeof unit.abilities === "undefined") {
      unit.abilities = []
    }
  })

  return profile
}
