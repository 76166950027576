
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as confirmmKDjP7OZW9Meta } from "/opt/build/repo/pages/(auth)/confirm.vue?macro=true";
import { default as login4EZta4O5WyMeta } from "/opt/build/repo/pages/(auth)/login.vue?macro=true";
import { default as reset_45passwordoh0pmRgZ16Meta } from "/opt/build/repo/pages/(auth)/reset-password.vue?macro=true";
import { default as sign_45upuRx5d67voMMeta } from "/opt/build/repo/pages/(auth)/sign-up.vue?macro=true";
import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as indexQHLGOCDfydMeta } from "/opt/build/repo/pages/account/index.vue?macro=true";
import { default as update_45passwordpGm9r8D0JOMeta } from "/opt/build/repo/pages/account/update-password.vue?macro=true";
import { default as _91_46_46_46slug_93Rhffn5woI4Meta } from "/opt/build/repo/pages/changelog/[...slug].vue?macro=true";
import { default as indexPrQQvOym5CMeta } from "/opt/build/repo/pages/changelog/index.vue?macro=true";
import { default as migrate_45preset_45modifiersSlrqbNAYkMMeta } from "/opt/build/repo/pages/dev/migrate-preset-modifiers.vue?macro=true";
import { default as migrate_45preset_45profilesZWmYgWl3HDMeta } from "/opt/build/repo/pages/dev/migrate-preset-profiles.vue?macro=true";
import { default as modifier_45sandboxZYOHGYtgK5Meta } from "/opt/build/repo/pages/dev/modifier-sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93Sh0q7vWDJJMeta } from "/opt/build/repo/pages/faq/[...slug].vue?macro=true";
import { default as indexWVSQuX67eqMeta } from "/opt/build/repo/pages/faq/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as manage_45profilesOsemOsWt1gMeta } from "/opt/build/repo/pages/manage-profiles.vue?macro=true";
import { default as send_45debug_45datazU39umJo7UMeta } from "/opt/build/repo/pages/send-debug-data.vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "confirm",
    path: "/confirm",
    meta: confirmmKDjP7OZW9Meta || {},
    component: () => import("/opt/build/repo/pages/(auth)/confirm.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login4EZta4O5WyMeta || {},
    component: () => import("/opt/build/repo/pages/(auth)/login.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordoh0pmRgZ16Meta || {},
    component: () => import("/opt/build/repo/pages/(auth)/reset-password.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upuRx5d67voMMeta || {},
    component: () => import("/opt/build/repo/pages/(auth)/sign-up.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexQHLGOCDfydMeta || {},
    component: () => import("/opt/build/repo/pages/account/index.vue")
  },
  {
    name: "account-update-password",
    path: "/account/update-password",
    meta: update_45passwordpGm9r8D0JOMeta || {},
    component: () => import("/opt/build/repo/pages/account/update-password.vue")
  },
  {
    name: "changelog-slug",
    path: "/changelog/:slug(.*)*",
    meta: _91_46_46_46slug_93Rhffn5woI4Meta || {},
    component: () => import("/opt/build/repo/pages/changelog/[...slug].vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    meta: indexPrQQvOym5CMeta || {},
    component: () => import("/opt/build/repo/pages/changelog/index.vue")
  },
  {
    name: "dev-migrate-preset-modifiers",
    path: "/dev/migrate-preset-modifiers",
    meta: migrate_45preset_45modifiersSlrqbNAYkMMeta || {},
    component: () => import("/opt/build/repo/pages/dev/migrate-preset-modifiers.vue")
  },
  {
    name: "dev-migrate-preset-profiles",
    path: "/dev/migrate-preset-profiles",
    meta: migrate_45preset_45profilesZWmYgWl3HDMeta || {},
    component: () => import("/opt/build/repo/pages/dev/migrate-preset-profiles.vue")
  },
  {
    name: "dev-modifier-sandbox",
    path: "/dev/modifier-sandbox",
    component: () => import("/opt/build/repo/pages/dev/modifier-sandbox.vue")
  },
  {
    name: "faq-slug",
    path: "/faq/:slug(.*)*",
    meta: _91_46_46_46slug_93Sh0q7vWDJJMeta || {},
    component: () => import("/opt/build/repo/pages/faq/[...slug].vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: indexWVSQuX67eqMeta || {},
    component: () => import("/opt/build/repo/pages/faq/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "manage-profiles",
    path: "/manage-profiles",
    component: () => import("/opt/build/repo/pages/manage-profiles.vue")
  },
  {
    name: "send-debug-data",
    path: "/send-debug-data",
    meta: send_45debug_45datazU39umJo7UMeta || {},
    component: () => import("/opt/build/repo/pages/send-debug-data.vue")
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/intro",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/known-issues",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/mathhammer",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/many-vs-one",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/simulate-attack",
    component: component_45stubRU5sOBTecH
  }
]