<script setup>
import supportedKeywords from "~/utils/supportedKeywords"
import UcSelect from "@/components/UC/UcSelect.vue"

const model = defineModel({
  type: Array,
  required: true,
})

const items = supportedKeywords
</script>

<template>
  <UcSelect
    v-model="model"
    :items="items"
    clearable
    deletable-chips
    label="Keywords"
    multiple
    small-chips
  />
</template>
