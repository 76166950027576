<script setup>
import { vMaska } from "maska/vue"
import UcTextField from "@/components/UC/UcTextField.vue"

defineProps({
  errorMessages: {
    type: Array,
    required: true,
  },
  hint: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    required: true,
  },
  max: {
    type: [Number, String],
    default: "6",
  },
  min: {
    type: [Number, String],
    default: "2",
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const model = defineModel({
  // Can't be required as it may receive a null value.
  type: [Number, String],
})
</script>

<template>
  <UcTextField
    v-model.number="model"
    v-maska
    :error-messages="errorMessages"
    :hint="hint"
    :label="label"
    :max="max"
    :min="min"
    outlined
    :required="required"
    type="number"
    data-maska="N"
    data-maska-tokens="N:[0-9]:multiple"
  />
</template>
