import { defineStore } from "pinia"
import { cloneDeep } from "es-toolkit"
import { useGlobalStore } from "@/stores/global"

export const useUpdateBackupStore = defineStore("updateBackup", {
  state: () => ({
    created: null,
    modifiersGlobal: null,
    profiles: null,
  }),
  actions: {
    backupData() {
      const global = useGlobalStore()
      console.log("Backing up data...")
      this.modifiersGlobal = cloneDeep(global.modifiersGlobal)
      this.profiles = cloneDeep(global.profiles)
      this.created = Date.now()
      console.log("Data backed up.")
    },
    clearBackupData() {
      console.log("Clearing backup data...")
      this.created = null
      this.modifiersGlobal = null
      this.profiles = null
      console.log("Backup data cleared.")
    },
    restoreBackupData() {
      console.log("Restoring backup data...")
      const global = useGlobalStore()
      global.modifiersGlobal = this.modifiersGlobal
      global.profiles = this.profiles

      console.log("Backup data restored.")
    },
  },
  persist: {
    key: "unitcrunch.updateBackup",
    storage: piniaPluginPersistedstate.localStorage(),
  },
})
